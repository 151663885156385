export default {
  common: {
    rv: 'VR',
    moneyDisplay: '{amount} $',
    dateFormat: {
      short: 'D/MM',
      medium: 'D MMM'
    },
    yes: 'Oui',
    no: 'Non'
  },
  actions: {
    back: 'Retour',
    bookNow: 'Réservez maintenant',
    cancel: 'Annuler',
    continue: 'Continuer',
    done: 'Terminer',
    edit: 'Modifier',
    editProfile: 'Modifier mon profil',
    goBack: 'Retourner',
    goToDashboard: 'Aller au tableau de bord',
    hideBenefits: 'Cacher les avantages',
    learnMore: 'En savoir plus',
    login: 'Connexion',
    logOut: 'Déconnexion',
    loadMorePosts: 'Charger plus articles',
    moreInfo: "Plus d'informations",
    no: 'Non',
    next: 'Suivant',
    prev: 'Précédent',
    readMore: 'En lire plus',
    readLess: "Moins d'info",
    remove: 'Supprimer',
    requestToBook: 'Demande de réservation',
    save: 'Sauvegarder',
    saveAndExit: 'Sauvegarder et quitter',
    saveChanges: 'Sauvegarder',
    search: 'Recherche',
    select: 'Sélectionner',
    share: 'Partager',
    showMeMore: 'Me montrer plus',
    showMore: 'Montre plus',
    submit: 'Soumettre',
    showBenefits: 'Montrer les avantages',
    showLess: 'Montre moins',
    update: 'Mettre à jour',
    submitReview: 'Poster le commentaire',
    verify: 'Vérifier',
    recommended: 'Recommandation'
  },

  language: {
    english: 'Anglais',
    french: 'Français'
  },

  errors: {
    required: 'Ce champ est requis',
    serverErrors:
      'Il semble qu’il y a eu un problème lors de la soumission de vos informations. Veuillez réessayer plus tard.',
    invalidEmailAddress: 'Adresse courriel invalide',
    emailTaken: 'Cette addresse courriel est déjà utilisée',
    invalidPhoneNumber: 'Numéro de téléphone invalide',
    samePhoneNumber: 'Même numéro de téléphone que le numéro principal',
    invalidPostalZip: 'Code postal/ZIP invalide',
    invalidField: 'Valeur de champ invalide',
    minimumLength: 'Minimum de {length} charactères.',
    maximumLength: 'Maximum de {length} charactères.',
    minimumValue: 'Valeur minimale de {value}.',
    minimumPerNight: 'Mininum de {value}$ par nuit',
    minimumAmountAndPerNight: 'Minimum de {value}$ ({nightlyPrice}$ par nuit)',
    maximumValue: 'Valeur maximale de {value}',
    passwordDoesntMatch: "Le mot de passe n'est pas identique",
    noSimilarRvsFound: 'Aucun VR similaire trouvé',
    pleaseSelectDate: 'Veuillez sélectionner une date',
    imageSizeError: 'Votre image a dépassé la taille limite de téléversement de {size}.',
    fileExtensionError: 'Seuls les fichiers {extensions} sont autorisés.',
    invalidUrl: 'URL invalide'
  },
  new: 'Nouveau',
  hours: '1 heure | {n} heures',

  included: 'Inclu',
  unlimited: 'Illimité',
  optional: 'Optionnel',
  day: 'jour | jours',
  typeSomething: 'Écrivez quelque chose',

  select: {
    select: 'Sélectionner',
    country: '@:select.select un pays',
    region: '@:select.select une région',
    province: '@:select.select une province',
    state: '@:select.select un État',
    language: '@:select.select une langue',
    year: '@:select.select une année',
    month: '@:select.select un mois',
    day: '@:select.select un jour',
    reason: '@:select.select une raison'
  },

  booking: {
    closeout: {
      closoutChargesTooGreat:
        'Le montant des frais de clôture ne peut pas être supérieur à ({amount}), veuillez <a href="https://help.rvezy.com/hc/fr-ca/requests/new" target="_blank">contacter le support</a>'
    },
    /* cancel is problematic with yaml loader */
    cancel: {
      title: 'Êtes-vous sûr(e)?',
      description:
        'Vous et vos invités serez affectés par l’annulation. Vous pourriez être tenu à payer des frais, les dates seront bloquées dans votre calendrier et le placement de votre annonce dans les résultats de recherche pourrait être abaissé.',
      cancellationPolicy: "Politique d'annulation",
      cancelBooking: 'Annuler la réservation',
      cancelBookingSelling: 'Annuler la réservation | Annuler la réservation | Annuler {n} réservations',

      owner: {
        learnMoreURL:
          'https://help.rvezy.com/hc/fr-ca/articles/13441962169115-Comment-puis-je-demander-la-suppression-des-pénalités-d-annulation-',

        error: {
          title: "Oups, une erreur s'est produite",
          content: 'Nous avons rencontré un problème en essayant d’annuler votre réservation. Veuillez {0}',
          contactSupport: "l'équipe de support"
        },

        canceled: {
          title: 'Réservation annulée',
          success: 'Cette réservation a été annulée.',
          content: 'La position de votre VR dans les résultats de recherche a été temporairement réduite.',
          content_calendar:
            'Les dates du {startDate} au {endDate} ont été bloquées dans votre calendier. vous ne pourrez pas réserver un autre voyage durant cette période',

          selling: {
            title: 'Merci d’avoir affiché votre VR avec RVezy.',
            content: 'Votre VR sera supprimé le {date}.'
          },

          additionalBookings: {
            title: 'Les réservations supplémentaires ont été annulées.',
            text: 'Une réservation en conflit avec un paiement total de {payoutTotal} a été annulée. | {n} réservations en conflit avec un paiement total {payoutTotal} ont été annulées.'
          }
        },

        confirm: {
          title: '{amount} $ seront déduits de votre prochain versement.',
          learnMore: 'En savoir plus sur',
          learnMoreURL:
            'https://help.rvezy.com/hc/fr-ca/articles/13562362996891-Quelle-est-la-politique-d-annulation-pour-les-h%C3%B4tes-de-VR-'
        },

        'confirm-selling': {
          title: 'Vous êtes sur le point d’annuler',
          rvWillBeDeleted: 'Votre VR sera supprimé le {date}.',
          additionalBookingsWillBeCanceled: {
            title: ' Les réservations supplémentaires seront automatiquement annulées.',
            text: 'Une réservation en conflit avec un paiement total de {payoutTotal} sera automatiquement annulée. | {n} réservations en conflit avec un paiement total de {payoutTotal} seront automatiquement annulées.'
          }
        },

        message: {
          title: 'Envoyez un message au locataire',
          label1: 'Expliquez à {firstName} pourquoi vous annulez. | Expliquez leur pourquoi vous annulez.',
          label2: 'Envoyez-leur un message privé expliquant les circonstances.',
          placeholder: 'Désolé, je dois annuler parce que...',
          placeholderError: 'Ajouter un message pour {firstName} | Ajouter un message pour les locataires',
          required: 'Veuillez inscrire un message',
          minimumLength: 'Veuillez inscrire au moins {length} charactères'
        },

        calendar: {
          title: 'Quand est-ce que vous vendez votre VR?',
          selectADate: 'Sélectionner une date',
          selectDate: 'Sélectionner le {date}'
        },

        'guest-cancel': {
          title: 'Demandez plutôt à votre invité d’annuler '
        },

        form: {
          whyAreYouCanceling: 'Pourquoi annulez-vous ? *',
          placeholder: 'Sélectionner une raison',
          required: 'Veuillez sélectionner une raison pour annuler',
          reasonForCancelingTheBooking: "Raison de l'annulation de la réservation",
          MyRvIsNotAvailableForRentOnTheRequestedDates: {
            label: 'Raison de l’indisponibilité*',
            other: 'Raison de l’indisponibilité'
          },
          HaveConcernsWithThisRenter: {
            label: 'Pourquoi avez-vous des préoccupations à l’égard du locataire? *',
            other: 'Raison pour laquelle vous avez des inquiétudes au sujet du locataire'
          },
          MyRvIsNotFitForTheRoad: {
            label: 'Pourquoi votre VR n’est-il pas adapté à la route? *',
            other: "Raison pour laquelle votre VR n’est pas en condition d'être loué"
          },
          ThisRvHasBeenSoldOrWillBeSold: {
            label: 'Quand est-ce que vous vendez votre VR? *'
          },
          ExtenuatingCircumstances: {
            label: 'Quelles sont les circonstances atténuantes? *',
            other: 'Raison des circonstances atténuantes'
          },
          other: 'Raison de l’annulation de la réservation'
        },

        lostPayout: 'revenu perdu',

        pleaseExplain: {
          label: 'Veuillez expliquer*',
          required: 'Veuillez inscrire une raison'
        },

        consequences: {
          affectedavailability: {
            title: 'La disponibilité du VR sera affectée.',
            content: 'En annulant, vous bloquerez les dates du {startDate} au {endDate} dans votre calendrier.'
          },

          default: {
            title: 'L’annulation aura un impact sur le statut de votre compte.',
            calendar: 'calendrier',
            list: {
              0: 'L’annulatoin réduiera temporairement la position de votre VR dans les résultats de recherche.',
              1: 'Les dates de la réservation seront bloquées dans votre {0} et vous ne pourrez pas réserver un autre voyage durant cette période.'
            }
          },

          concerns: {
            title: 'Veuillez n’accepter que les réservations en lesquelles vous avez confiance.',
            list: {
              1: "Veuillez discuter des détails du voyage avec les locataires avant les réservations afin d'éviter d’annuler."
            }
          }
        },
        keepReservation: 'Garder la réservation',
        cancelReservation: 'Annuler la réservation'
      },
      renter: {
        youHavePaid: 'Vous avez payé',
        yourRefund: 'Votre remboursement',
        paidToDate: 'Payé à ce jour',
        nonRefundable: 'Non remboursable',
        nightlyRate: 'Tarif de nuit x {nights} (jours)',
        ownerSalesTax: 'Taxe de vente du propriétaire',
        serviceFee: 'Frais de service',
        tax: 'Taxe',
        totalRefund: 'Remboursement total',
        whyDoYouNeedToCancel: 'Pourquoi devez-vous annuler?*',
        reasonForCancellingTheBooking: "Raison de l'annulation de la réservation",
        reasons: {
          PersonalOrFamilyEmergency: 'Urgence personnelle ou familiale',
          COVID: 'COVID-19',
          AvailabilityOrChangeOfPlans: 'Changement de plan/voyage annulé',
          LackOfFunds: 'La réservation est trop coûteuse',
          UnresponsiveOwner: 'Le propriétaire ne répond pas',
          WeatherOrTravelRestrictions: 'Conditions météorologiques ou restrictions de voyage',
          Other: 'Autre'
        },
        creditsRefunded: '{amount} Crédits RVezy',
        yourCreditsWillReturnToYourAccount: 'Vos crédits RVezy retourneront à votre compte.',
        describeWhyYouAreCancelling: 'Donnez des détails sur les raisons pour lesquelles vous annulez votre réservation'
      },
      riskScore: {
        description: {
          renter:
            'Une combinaison de risques de sécurité suggèrent que cette réservation pose des risques de sécurité plus élevés, donc elle a été annulée. Un plein remboursement a été traité et devrait être reçu sur votre méthode de paiement d’ici trois à cinq jours ouvrables.',
          owner:
            'Nous avons détecté des risques de sécurité plus élevés pour cet voyageur, cette réservation a donc été annulée pour la sécurité de votre VR.'
        }
      }
    },

    details: {
      /* do not remove, used in js file */
      checklist: {
        preArrival: 'Préparation',
        departure: 'Départ',
        return: 'Retour',
        empty: 'Vide',
        full: 'Plein',
        odometer: 'Kilométrage',
        fuel: 'Carburant',
        generator: 'Génératrice',
        propane: 'Propane',
        inspectionResults: 'Inspections',
        mileageIncluded: '{mileage} {unit}/jour inclus. {value}/{unit} supplémentaire.',
        generatorIncluded: '{hours} heures/jour inclus. {value}/heure supplémentaire.',
        damageChargesDescription: 'Doit être inférieur au dépôt de garantie.',
        otherChargesDescription: 'Pour toute autre dépense imprévue.'
      }
    }
  },

  ratingCategories: {
    ListingAccuracy: 'Précision de l’annonce',
    Value: 'Rapport qualité-prix',
    Cleanliness: 'Propreté',
    Communication: 'Communication'
  },

  user: {
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    phoneNumber: 'Numéro de cellulaire',
    alternatePhoneNumber: 'Numéro de téléphone secondaire',
    email: 'Courriel',
    password: 'Mot de passe',
    position: {
      superOwner: 'Super propriétaire',
      owner: 'Propriétaire',
      renter: 'Locataire',
      superRenter: 'Super Locataire'
    }
  },

  rvType: {
    text: 'Type de VR',
    rvTypes: 'Types de VR',
    iconAlt: '`Icône RVezy {rentalType} {type}',
    ClassA: 'Classe A',
    ClassB: 'Classe B',
    ClassC: 'Classe C',
    TruckCamper: 'Caravane portée',
    Campervan: 'Campervan',

    FifthWheel: 'Sellette',
    Trailer: 'Roulotte',
    TravelTrailer: 'Roulotte',
    SubTravelTrailer: 'Roulotte',
    TentTrailer: 'Tente-roulotte',
    Hybrid: 'Hybride',
    ToyHauler: 'Roulotte cargo',
    MicroTrailer: 'Mini-roulotte',
    Motorhome: 'Motorisé',
    MotorHome: 'Motorisé',
    RVCottage: 'Chalet VR',

    rvCottage: 'Chalet VR'
  },

  rv: {
    hitchWeight: "Poids de l'attelage",
    hitchSize: "Taille de l'attelage",
    length: 'Longueur (pi)',
    sleeps: 'Nombre de personnes',
    slideouts: "No. d'extensions coulissantes",
    weight: 'Poids',

    preferences: {
      experiencedDriverRequired: 'Conducteur expérimenté requis',
      towingExperienceRequired: 'Expérience de remorquage requise',
      noFestivalsOrEvents: 'Aucun festivals ou événements',
      noCrossBorderTravel: 'Aucun voyage transfrontalier permis',
      noPetsAllowed: 'Pas d’animaux'
    },

    noDeliveryDistanceFound: {
      warningText:
        'Le VR ne peut pas être livré à cette adresse. Vous pouvez envoyer un message à l’hôte pour discuter des options de livraison.'
    },

    crossBorderTravel: {
      warning: {
        text: 'Les voyages à l’extérieur {country} ne sont pas autorisés pour ce VR.',
        description: 'Malheureusement, vous ne répondez pas aux exigences de l’hôte pour réserver ce VR.',
        descriptionButton:
          'Malheureusement, vous ne répondez pas aux exigences de l’hôte pour réserver ce VR. Trouvons un VR adapté à votre voyage!',
        countries: {
          CA: 'du Canada',
          US: 'des États-Unis',
          default: 'du pays'
        }
      }
    }
  },

  faqs: {
    heading: 'Foire aux questions'
  },

  pageLink: {
    aboutRVezy: 'À propos de RVezy',
    account: 'Compte',
    blog: 'Blogue',
    calendar: 'Calendrier',
    cancellationPolicy: "Politique d'annulation",
    contactUs: 'Contactez-nous',
    dashboard: 'Tableau de bord',
    favourites: 'Favoris',
    helpCenter: "Centre d'aide",
    help: 'Aide',
    home: 'Accueil',
    howRVezyWorks: 'Comment fonctionne RVezy',
    inbox: 'Messages',
    myRvezy: 'Mon RVezy',
    listAnRV: 'Inscrivez un VR',
    privacyPolicy: 'Politique de confidentialité',
    profile: 'Profil',
    rvRentals: 'Locations de VR',
    searchRVs: 'Rechercher un VR',
    termsOfService: "Termes d'utilisation",
    insights: 'Analyses'
  },

  urls: {
    matchMaker: 'https://rvezy.typeform.com/to/Pi9U7I'
  },

  helpUrls: {
    newRequest: 'https://help.rvezy.com/hc/fr-ca/requests/new',
    cancellationPolicy: 'https://help.rvezy.com/hc/fr-ca/articles/360037736674',
    ownerResponseRate: 'https://help.rvezy.com/hc/fr-ca/articles/4415854395291',
    verifyMyIdentity: 'https://help.rvezy.com/hc/fr-ca/articles/1260804182169',
    riskCancellationOwner:
      'https://help.rvezy.com/hc/fr-ca/articles/10509734333339-Keeping-the-RVezy-community-safe-for-hosts',
    riskCancellationRenter:
      'https://help.rvezy.com/hc/fr-ca/articles/10510239683611-Keeping-the-RVezy-community-safe-for-guests'
  },

  pages: {
    maintenance: {
      metaTitle: 'RVezy - Hors ligne pour maintenance, revenez plus tard'
    },

    owner: {
      editRV: {
        saveSuccessfully: 'Sauvegarde effectuée!',

        pages: {
          basics: 'Infos',
          details: 'Détails',
          pricing: 'Prix',
          photos: 'Photos',
          addOns: 'Éléments supplémentaires',
          earnMore: 'Gagner plus',
          insurance: 'Assurance',
          availability: 'Disponibilité',
          calendar: 'Calendrier',
          preferences: 'Préferences'
        },

        pricing: {
          baseNightPrice: {
            title: 'Prix de base par nuit',
            subtext:
              "Ceci est le tarif de réservation par nuit. Il sera applicable pour toutes les dates à l'exception de celles qui ont un tarif personnalisé. Vous pourrez modifier ce tarif à tout moment.",
            error: 'Le prix doit être entre 20$ et 5000$'
          },

          securityDeposit: {
            title: 'Dépôt de sécurité',
            subtext:
              "Le dépôt de sécurité sera débité de la carte de crédit du locataire 3 jours avant le début de la location. RVezy détiendra ce montant jusqu'au retour du VR et après la finalisation de la location par le propriétaire et le locataire sur notre site. S'il y a des dommages matériels ou des frais additionnels, nous utiliserons le dépôt de sécurité pour compenser le propriétaire. Nous recommendons un dépôt de sécurité de 1000$."
          },
          cleaningFee: {
            title: 'Frais de nettoyage',
            subtext:
              "Les frais de nettoyage seront appliqués sur toutes les réservations. Il est attendu que le locataire rende le VR dans la même condition dans laquelle il la reçu. Ces frais sont une compensation pour le temps et l'effort du proprietaire à préparer le VR pour le locataire."
          },

          generator: {
            title: 'Génératrice',
            maxHourValueError: 'La valeur doit être égale ou inférieure à 23',
            maxCostValueError: 'La valeur doit être égale ou inférieure à 20',
            tooltip:
              "<b>Utilisation de la génératrice illimité</b><br/>Ceci permet aux locataires d'utiliser votre génératrice durant la période de location sans frais additionnels.<br/><br/><b>Heures incluses(Optionel)</b><br/>Ceci s'agit du nombre d'heures par jours que le locataire peu utiliser la génératrice gratuitement.<br/><br/><b>Frais additionel de l'heure (Optionnel)</b><br/>Ceci s'agit du frais à l'heure pour l'utilisation additionnel de la génératrice lorsque le locataire dépasse le nombre d'heures permis par jour."
          }
        },

        details: {
          lengthError: 'Doit être entre 8 pieds et 50 pieds',
          weightError: 'Doit être compris entre 1 livre et 20000 livres',
          hitchWeightError: 'Doit être au moins 1 livre',
          seatbeltTwoPoint: 'No. de ceintures de sécurité ventrales',
          seatbeltThreePoint: 'No. de ceintures de sécurité diagonales'
        }
      }
    },

    index: {
      metaTitle: 'Locations de VR dès 47$/nuit | #1 Site de location de VR',
      metaDesc:
        'RVezy est le plus grand et le plus sûr marché de location de véhicules récréatifs au Canada. Trouvez des véhicules récréatifs, des motorisés et des roulottes de côte à côte avec une assurance de 2 M$, une assistance routière 24/7, des paiements sécuritaires et un soutien personnel bilingue.'
    },

    profile: {
      greeting: 'Bonjour',
      pageTitles: {
        profile: 'Profil',
        settings: 'Paramètres du compte',
        payout: 'Informations de paiement',
        notifications: 'Paramètres de notification',
        goals: "Objectifs de l'hôte",
        messageTemplates: 'Modèles de message',
        drivers: 'Conducteurs',
        features: 'Fonctionnalités',
        payment: 'Méthodes de paiement'
      },
      account: {
        phonePlaceholder: 'Entrer un numéro de téléphone'
      },

      driver: {
        addDriver: 'Ajouter un conducteur',
        licenseRequired: 'Permis de conduire obligatoire',
        verification: {
          Approved: 'Approuvé',
          NotApproved: 'Non approuvé',
          ValidationFailed: 'Validation échouée',
          InProcessByRVezy: 'Vérification en cours par RVezy',
          VerificationInProgress: 'En cours'
        },
        addNewDriver: {
          title: 'Ajouter un nouveau conducteur'
        }
      },

      notification: {
        success: 'Sauvegarde effectuée!',
        error: 'Erreur est survenue'
      }
    },

    rvDetails: {
      metaTitle: {
        default: 'Louez mon {model} pour {price}/nuit',
        rvCottage: 'Louez mon Chalet VR pour {price}/nuit'
      },
      metaDesc:
        '{rvType} à louer à {city}, {state}. Couche {sleeps} personnes. Rabais hebdomadaire et mensuel. Meilleur prix garantie. Voir les disponibilités aujourd’hui.'
    },

    requestToBook: {
      metaTitle: 'Réserver ce {year} {make} {model}',
      metaDesc:
        'Réservez ce {year} {make} {model} pour votre prochaine aventure ! Ce {rentalType} est situé à {city}, {state}.'
    }
  },

  /**
   * Skip: these are used in JS files.
   */
  doka: {
    labelReset: 'Réinitialiser',
    labelDefault: 'Par défaut',
    labelAuto: 'Auto',
    labelNone: 'Aucun',
    labelEdit: 'Modifier',
    labelClose: 'Fermer',
    labelSizeExtraSmall: 'Très petit',
    labelSizeSmall: 'Petit',
    labelSizeMediumSmall: 'Moyen petit',
    labelSizeMedium: 'Moyen',
    labelSizeMediumLarge: 'Moyen grand',
    labelSizeLarge: 'Grand',
    labelSizeExtraLarge: 'Très grand',
    labelButtonRevert: 'Revenir',
    labelButtonCancel: 'Annuler',
    labelButtonUndo: 'Annuler',
    labelButtonRedo: 'Refaire',
    labelButtonExport: 'Fait',
    statusLabelButtonClose: 'Fermer',
    cropLabel: 'Recadrer',
    cropLabelButtonRecenter: 'Recentrer',
    cropLabelButtonRotateLeft: 'Tourner à gauche',
    cropLabelButtonRotateRight: 'Tourner à droite',
    cropLabelButtonFlipHorizontal: 'Basculer horizontalement',
    cropLabelButtonFlipVertical: 'Basculer verticalement',
    cropLabelSelectPreset: 'Forme du recadrement',
    cropLabelCropBoundary: 'Limite du recadrement',
    cropLabelCropBoundaryEdge: 'Bord de l’image',
    cropLabelCropBoundaryNone: 'Aucun',
    cropLabelTabRotation: 'Rotation',
    cropLabelTabZoom: 'Zoom',
    filterLabel: 'Filtrer',
    filterLabelChrome: 'Chrome',
    filterLabelFade: 'Fané',
    filterLabelCold: 'Froid',
    filterLabelWarm: 'Chaud',
    filterLabelPastel: 'Pastel',
    filterLabelMonoDefault: 'Mono',
    filterLabelMonoNoir: 'Noir',
    filterLabelMonoWash: 'Wash',
    filterLabelMonoStark: 'Stark',
    filterLabelSepiaDefault: 'Sepia',
    filterLabelSepiaBlues: 'Bleus',
    filterLabelSepiaRust: 'Rouille',
    filterLabelSepiaColor: 'Couleur',
    finetuneLabel: 'Affiner',
    finetuneLabelBrightness: 'Luminosité',
    finetuneLabelContrast: 'Contraste',
    finetuneLabelSaturation: 'Saturation',
    finetuneLabelExposure: 'Exposition',
    finetuneLabelTemperature: 'Température',
    finetuneLabelGamma: 'Gamma',
    finetuneLabelClarity: 'Clarté',
    finetuneLabelVignette: 'Vignette',
    notSupportedOnThisBrowser: 'non supporté par ce navigateur',
    waitingForImage: "En attente de l'image",
    errorLoadingImage: 'Erreur de chargement de l’image',
    creatingPreview: 'Création d’un aperçu…',
    loadingImage: 'Chargement de l’image…',
    errorUploadingImage: 'Erreur de téléchargement de l’image',
    uploadingImage: 'Téléchargement de l’image…',
    errorProcessingImage: "Erreur de traitement de l'image",
    processingImage: 'Traitement de l’image…'
  },

  /**
   * Skip: these are used in JS files.
   *
   * The addon titles and descriptions key must stay the same for now because we
   * use strings stored in the addons backend to look up translations
   */
  DASHBOARD: {
    ADDONS: {
      SUBHEADING: "Sélectionnez l'un des éléments complémentaires qui s'applique à votre annonce.",
      DELIVERY_RENTER: 'Livraison au locataire',
      DELIVERY_RENTER_DESC: 'Offrir la livraison du VR à un terrain de camping ou un autre lieu',
      PER_NIGHT: 'Par Nuit',
      PER_TRIP: 'Par Voyage',
      PRICE_PER_NIGHT: 'Prix par nuit',
      PRICE_PER_TRIP: 'Prix par voyage',
      DELIVERY: 'Livraison jusqu’à {distance} {unit}',
      AIRPORT_PICKUP: "Récupération de l'aéroport",
      AIRPORT_PICKUP_DESC: "Allez chercher les locataires à l'aéroport et les amener au VR",
      BEDDING_LINENS: 'Literie et draps',
      BEDDING_LINENS_DESC: 'Fournir des draps, des serviettes, etc.',
      PORTABLE_GPS: 'GPS portable',
      BIKE_RACK: 'Support à vélo',
      PORTABLE_BBQ: 'Barbecue portatif',
      PORTABLE_GENERATOR: 'Génératrice portable',
      WIFI: 'WIFI',
      CUSTOM_PACKAGE: 'Élément supplémentaire personalisé',
      CUSTOM_PACKAGE_DESC: "Chargez un frais additionnel pour l'équipement compris avec la location",
      CONVENIENCE_PACKAGE: 'Trousse pratique',
      CONVENIENCE_PACKAGE_DESC: 'Soyez original! Créez une trousse personalisée pour les locataires de votre VR',
      PROPANE_REFILL: 'Prépayez le remplissage du réservoir de propane.',
      PROPANE_REFILL_DESC: 'Permettez aux locataires de ramener le VR sans remplir le réservoir de propane.',
      FUEL_REFILL_PREPAYMENT: 'Prépayez le remplissage du réservoir de carburant.',
      FUEL_REFILL_PREPAYMENT_DESC: 'Permettez aux locataires de ramener le VR sans remplir le réservoir de carburant.',
      EMPTYING_SEPTIC_PREPAYMENT: 'Prépayez le vidage du réservoir septique.',
      EMPTYING_SEPTIC_PREPAYMENT_DESC: 'Permettez aux locataires de ramener le VR sans vider le réservoir septique.',
      EMTPYING_SEPTIC_PREPAYMENT: 'Prépayez le vidage du réservoir septique.',
      EMTPYING_SEPTIC_PREPAYMENT_DESC: 'Permettez aux locataires de ramener le VR sans vider le réservoir septique.',
      PICKUP_FROM: 'Ramassage de',
      PRICE: 'Prix',
      SELECT_AIRPORT: 'Sélectionnez un aéroport',
      SUGGESTED_PRICE: 'Prix suggéré : {price}',
      SUGGESTED_DISCOUNT: 'Remise suggérée',
      DISTANCE_INCLUDES: 'Cette distance inclue la livraison et la récupération du VR',
      DESCRIPTION: 'Description',
      CUSTOM_TOTAL: 'Total personnalisé'
    }
  },

  // Skip for now, as this errors out with vue-i18n's YAML parser
  'new-rv': {
    'suggested-price': 'Prix suggéré:'
  }
}
