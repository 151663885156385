export default function (device) {
  if (device.isAndroid) return 'android'
  if (device.isIos) return 'ios'
  if (device.isMacOS) return 'macos'
  if (device.isWindows) return 'windows'

  return 'other'
}
//Because when using a WebView in a native app, $device.isIOS returns false even though it is true. I need to write another method to check for that.
export function isIOSDevice(device) {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(device.platform) ||
    // iPad on iOS 13 detection
    (device.userAgent.includes('Mac') && 'ontouchend' in document) ||
    device.userAgent.includes('ios')
  )
}
