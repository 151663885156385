import { trackUserDeauthentication } from '~/lib/tracking'
import { trackStartup } from '~/lib/experiments/utils'
import { isWebview } from '~/lib/useUtils'
import { UserManager } from 'oidc-client-ts'
import useBraze from '~/lib/useBraze'

export default async function ({ app, $auth, $axios, $cookies, $config, $captureError, nuxtState }, inject) {
  if (process.server) {
    /**
     * Get the user's access token from the cookies. If this is missing, or it's
     * not the same as the token stored in $auth, then force a logout of the
     * user. Otherwise grab additional user data.
     */
    const accessToken = $cookies.get('access_token')

    const removeAccessTokenCookie = () => {
      $cookies.remove('access_token', {
        domain: $config.cookieDomain,
        path: '/'
      })
    }

    // 1. User has an access_token cookie, but isn't authed yet, so log them in.
    // 2. user has no access_token cookie, but is authed, or the cookie and token don't match, so log them out.
    // 3. user is completely authed, so get additional details.
    try {
      if (accessToken && !$auth.loggedIn) {
        await $auth.setUserToken(`Bearer ${accessToken}`)

        if (!$auth.loggedIn) {
          throw new Error('access_token not valid')
        }
      } else if (!accessToken && $auth.loggedIn) {
        await $auth.logout()

        throw new Error('no access_token or access_token does not match auth token')
      }

      if ($auth.loggedIn) {
        await getUserExtraInfo()
      }
    } catch (e) {
      removeAccessTokenCookie()
    }
  }

  async function getUserExtraInfo() {
    await Promise.all([$axios.$get(`${$config.apiUrl}/api/account/me/permissions`)])
      .then(([permissions]) => {
        $auth.$storage.setState('permissions', permissions)
      })
      .catch((err) => {
        $captureError(err)
      })
  }

  inject('authUtils', {
    initAuthProcess({ disableRedirectAfterSuccessAndRun, redirectSuggestedAfterAuthSuccess }) {
      this.disableRedirectAfterSuccessAndRun = disableRedirectAfterSuccessAndRun
      this.redirectSuggestedAfterAuthSuccess = redirectSuggestedAfterAuthSuccess
    },

    disableRedirectAfterSuccessAndRun: null,

    redirectSuggestedAfterAuthSuccess: null,

    async authFinishedWithSuccess() {
      if (this.disableRedirectAfterSuccessAndRun) {
        this.disableRedirectAfterSuccessAndRun()
      } else if (this.redirectSuggestedAfterAuthSuccess) {
        this.redirectSuggestedAfterAuthSuccess()
      }
    },

    getUserExtraInfo,

    async logout() {
      $cookies.remove('access_token', {
        domain: $config.cookieDomain,
        path: '/'
      })

      const externalProvider = $cookies.get('ris-provider')
      $cookies.remove('ris-provider', {
        domain: $config.cookieDomain,
        path: '/'
      })

      if (externalProvider) {
        const config = this.authConfig(externalProvider)
        config.extraQueryParams.lang = app.i18n.locale
        const userManager = new UserManager(config)

        await userManager.signoutSilent()
      }

      await $auth.logout()

      $auth.$storage.removeState('permissions')

      trackUserDeauthentication()

      const brazeApi = await useBraze()
      brazeApi.reset()

      app.router.push(app.localePath({ name: 'index', query: { logout: true } }))
    },

    authConfig(logInProvider) {
      switch (logInProvider) {
        case 'facebook':
          return $config.facebookAuthConfig
        case 'google':
          return $config.googleAuthConfig
        case 'apple':
          return $config.appleAuthConfig
        default:
          return null
      }
    }
  })

  $auth.refreshUser = async function () {
    await $auth.setUserToken(`Bearer ${$cookies.get('access_token')}`)
  }

  // Identify user on page load
  if (process.client) {
    if ($auth.loggedIn) {
      app.$identifyUser($auth.user)
    }
    // Track Server side requested Experiments on the client
    if (nuxtState.experiments) {
      trackStartup(nuxtState.experiments.evaluated, isWebview($cookies.get('platform')))
    }
  }

  $auth.onRedirect((_, from) => {
    $cookies.set('unauthorized-redirect', from, {
      domain: $config.cookieDomain,
      path: '/',
      maxAge: 3600
    })
  })
}
