import { render, staticRenderFns } from "./dashboard-navbar.vue?vue&type=template&id=4270113e"
import script from "./dashboard-navbar.vue?vue&type=script&lang=js"
export * from "./dashboard-navbar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./dashboard-navbar.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZNavItem: require('/home/vsts/work/1/s/components/style-guide/nav-item.vue').default,ZNavbarNav: require('/home/vsts/work/1/s/components/style-guide/navbar-nav.vue').default})
