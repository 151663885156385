
import { trackTipsAndResourcesCardClicked } from '~/lib/tracking'
export default {
  name: 'FeaturedHostBlog',

  data() {
    return {
      blogs: []
    }
  },

  created() {
    this.loadContent()
  },

  methods: {
    loadContent() {
      const tag = this.$i18n.locale === 'fr' ? 'hash-host-tips-featured-fr' : 'hash-host-tips-featured'
      this.$external
        .$get(`${this.$config.blog.ghostApiUrl}/posts?&filter=tag:${tag}`, {
          params: {
            key: this.$config.blog.ghostApiKey
          }
        })
        .then((res) => {
          this.blogs = res.posts.slice(0, 4).map((post) => {
            const url = `${this.localePath({
              name: 'blog'
            })}/${post.slug}`
            return { title: post.title, image: post.feature_image, url }
          })
        })
        .catch((err) => {
          this.$captureError(err)
        })
    },

    trackEvent(blog) {
      trackTipsAndResourcesCardClicked({
        accountCountry: this.$auth.user?.CountryString?.toLowerCase(),
        userId: this.$auth.user?.Id,
        tipsAndResourcesCardName: blog.title,
        pageSource: this.getRouteBaseName()
      })
    }
  }
}
