
import ownerCommon from '@/mixins/owner-common.js'
import route from '@/mixins/route.js'
import { trackHelpCenterClicked, trackSuperhostLearnMoreClicked } from '~/lib/tracking'
import { RouteBaseNameEnum } from '~/lib/enums'
import useCampgroundUrl from '~/lib/useCampgroundUrl'

export default {
  mixins: [ownerCommon, route],

  props: {
    isSticky: {
      type: Boolean,
      default: false
    },

    isHostVariant: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isOpen: false,

      menuItems: {
        en: {
          'host-links-support': {
            'help-center': 'https://help.rvezy.com/hc/en-us',
            'how-it-works': '/how-it-works#how-to-list-your-rv',
            'service-fees': '/service-fees',
            'cancellation-policy':
              'https://help.rvezy.com/hc/en-us/articles/13562362996891-What-is-the-cancellation-policy-for-RV-hosts',

            'contact-us': 'https://help.rvezy.com/hc/en-us/requests/new'
          },

          'host-links-insurance': {
            'overview-for-hosts': '/insurance-and-protection',
            'what-is-covered-not-covered':
              'https://help.rvezy.com/hc/en-us/articles/34006572813851-Summary-of-Owner-Protection-Plans',

            'border-crossing-coverage':
              'https://help.rvezy.com/hc/en-us/articles/360039892093-Is-a-guest-allowed-to-cross-the-US-Canada-border-with-my-RV',

            'what-is-the-insurance-application':
              'https://help.rvezy.com/hc/en-us/articles/360039295194-What-do-I-need-to-qualify-for-RVezy-s-insurance',

            'restricted-locations':
              'https://help.rvezy.com/hc/en-us/articles/360039340074-Where-is-RVezy-s-insurance-coverage-valid'
          },

          'host-links-hosting': {
            'listing-your-rv': '/owner',
            'learn-about-new-features': '/category/product-blog',
            'getting-a-5-star-review': '/blog/how-to-get-5-star-reviews-everytime',
            'getting-paid-as-a-host': 'https://help.rvezy.com/hc/en-us/articles/360038105934-How-and-when-do-I-get-paid'
          }
        },

        fr: {
          'host-links-support': {
            'help-center': 'https://help.rvezy.com/hc/fr-ca',
            'how-it-works': '/how-it-works#how-to-list-your-rv',
            'service-fees': '/service-fees',
            'cancellation-policy':
              'https://help.rvezy.com/hc/fr-ca/articles/13562362996891-Quelle-est-la-politique-d-annulation-pour-les-h%C3%B4tes-de-VR',

            'contact-us': 'https://help.rvezy.com/hc/fr-ca/requests/new'
          },

          'host-links-insurance': {
            'overview-for-hosts': '/insurance-and-protection',
            'what-is-covered-not-covered':
              'https://help.rvezy.com/hc/fr-ca/articles/34006572813851-R%C3%A9sum%C3%A9-des-plans-de-protection-des-propri%C3%A9taires',

            'border-crossing-coverage':
              'https://help.rvezy.com/hc/fr-ca/articles/360039892093-Est-ce-que-le-voyageur-a-le-droit-de-traverser-la-fronti%C3%A8res-Canada-%C3%89tats-Unis-avec-mon-VR',

            'what-is-the-insurance-application':
              'https://help.rvezy.com/hc/fr-ca/articles/360039295194-Quelles-sont-les-exigences-pour-%C3%AAtre-admissible-%C3%A0-l-assurance-de-RVezy',

            'restricted-locations':
              'https://help.rvezy.com/hc/fr-ca/articles/360039340074-O%C3%B9-la-couverture-d-assurance-RVezy-est-elle-valable'
          },

          'host-links-hosting': {
            'listing-your-rv': '/owner',
            'learn-about-new-features': '/category/product-blog',
            'getting-a-5-star-review': '/blog/how-to-get-5-star-reviews-everytime',
            'getting-paid-as-a-host':
              'https://help.rvezy.com/hc/fr-ca/articles/360038105934-Comment-et-quand-suis-je-pay%C3%A9'
          }
        }
      }
    }
  },

  head() {
    const i18nSeo = this.$nuxtI18nHead()

    if (this.isSticky) {
      i18nSeo.bodyAttrs = {
        class: 'footer-padding'
      }
    }

    return i18nSeo
  },

  computed: {
    menuItemsLocalized() {
      return this.menuItems[this.$i18n.locale]
    },

    currentYear() {
      return this.$moment().year()
    },

    isCompact() {
      return this.isRequestToBookPage || this.isPaymentPage
    },

    isExpanded() {
      return this.isOpen && !this.isCompact
    },

    isSearchPage() {
      return this.getRouteBaseName() === RouteBaseNameEnum.RV_SEARCH
    },

    cssProps() {
      return {
        '--footer-gap': this.isCompact ? '1.5rem' : '2.5rem',
        '--footer-padding': this.isCompact ? '1.5rem' : '2.5rem 0',
        '--footer-margin-bottom': (this.isCompact && !this.isPaymentPage) || this.isRentalPage ? '116px' : '0'
      }
    },

    sections() {
      return ['links-support', 'links-insurance-information', 'links-hosting']
    },

    campgroundsUrl() {
      const { getCampgroundsUrl } = useCampgroundUrl({ $config: this.$config, $i18n: this.$i18n })

      return getCampgroundsUrl()
    }
  },

  methods: {
    trackHelpCenterClicked() {
      trackHelpCenterClicked({
        cta: 'footer',
        pageSource: this.getRouteBaseName()
      })
    },

    trackSuperhostClicked() {
      trackSuperhostLearnMoreClicked({
        accountCountry: this.$auth.user?.CountryString?.toLowerCase(),
        isSuperhost: this.$auth.user?.SuperHost?.IsActive,
        pageSource: this.getRouteBaseName()
      })
    },

    toggleExpand() {
      this.isOpen = !this.isOpen
    },

    isExternal(url) {
      if (url.includes('http')) return true
    }
  }
}
