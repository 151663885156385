
import layoutMixin from '~/mixins/layout'
import setLayout from '@/lib/setLayout'
import { injectPartytownScripts } from '~/lib/partytown'

export default {
  mixins: [layoutMixin],

  layout({ $cookies }) {
    return setLayout($cookies)
  },

  props: {
    error: {
      type: Object,
      required: true
    }
  },

  head() {
    const title =
      this.error.statusCode === 404 ? this.$t('pages.error.title.fourOhFour') : this.$t('pages.error.title.others')

    return {
      title,
      ...injectPartytownScripts({
        $config: this.$config,
        $experiment: this.$experiment,
        $cookies: this.$cookies,
        $user: this.$auth.user
      })
    }
  }
}
