
import useRouteManager from '~/lib/useRouteManager.js'
import { QueryStrings } from '~/lib/enums/index.js'

export default {
  name: 'RentAnRvButton',
  props: {
    textBtn: {
      type: String,
      required: true
    },

    useHighlight: {
      type: Boolean,
      default: false
    },

    useWhite: {
      type: Boolean,
      default: false
    },

    isLink: {
      type: Boolean,
      default: false
    },

    usePrimary200: {
      type: Boolean,
      default: false
    },

    useOutline: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      locationModel: this.$search.parameters.location
    }
  },

  computed: {
    btnVariant() {
      return this.useHighlight ? 'highlight' : this.useWhite ? 'white' : 'primary'
    },

    targetUrl() {
      return `${this.$config.apps.marketplace.baseUrl}/${this.$i18n.locale}`
    }
  },

  watch: {
    '$search.parameters.location': {
      handler: function (newVal) {
        this.locationModel = newVal
      },

      deep: true
    }
  },

  methods: {
    rentAnRvLinkClicked() {
      const searchPath = this.localePath('rv-search')
      const newQuery = {
        [QueryStrings.searchAddress]: this.locationModel?.fullName || undefined
      }

      const { shouldRedirectToNuxt3App, nuxt3BaseUrl } = useRouteManager({
        toPath: searchPath,
        $config: this.$config,
        $experiment: this.$experiment
      })

      if (shouldRedirectToNuxt3App) {
        const filtered = Object.keys(newQuery)
          .filter((x) => newQuery[x] !== undefined)
          .reduce((acc, x) => {
            acc[x] = newQuery[x] === null ? newQuery[x] : String(newQuery[x])
            return acc
          }, {})

        const url = new URL(`${nuxt3BaseUrl}${searchPath}?${new URLSearchParams(filtered)}`)
        window.location.href = url.toString()
      } else {
        this.$router.push({
          path: searchPath,
          query: newQuery
        })
      }
    }
  }
}
