import { isDev, isTrue } from '../env.js'
import { injectDatadog, forwardDatadog } from './datadog.js'
import { injectSegment, forwardSegment, injectSegmentPlugin } from './segment.js'
import { injectGtagBase, injectGtag, forwardGoogleGtag } from './googleGTag.js'
import { forwardClarity, injectClarity } from './clarity.js'

const isPartytownEnabled = isTrue(process.env.PARTYTOWN_ENABLED)

if (isDev && process.server) {
  // eslint-disable-next-line no-console
  console.log(`🎉 Partytown is ${isPartytownEnabled ? 'enabled' : 'disabled'}`)
}

// A list of all the forwards that Partytown needs to handle.
export const partytownForwards = [...forwardDatadog, ...forwardSegment, ...forwardGoogleGtag, ...forwardClarity]

// Generates the head scripts to inject.
export const injectPartytownScripts = ({ $config, $experiment, $cookies, $user, $query }) => {
  // Filter undefined/disabled scripts
  const script = [
    injectDatadog({
      $partytown: $config.partytown,
      $config: $config.datadog.common,
      $experiment,
      $cookies,
      $user
    }),
    injectSegment({ $partytown: $config.partytown, $config: $config.segment, $cookies }),
    injectSegmentPlugin({ $partytown: $config.partytown, $config: $config.segment }),
    injectGtagBase({
      $partytown: $config.partytown,
      $config: $config.gtag,
      $googleAnalyticsConfig: $config.googleAnalytics
    }),
    injectGtag({
      $partytown: $config.partytown,
      $config: $config.gtag,
      $googleAnalyticsConfig: $config.googleAnalytics,
      $googleAdsConfig: $config.googleAds,
      $user,
      $query
    }),
    injectClarity({ $partytown: $config.partytown, $experiment, $config: $config.clarity })
  ].filter((item) => item)

  return {
    script,
    __dangerouslyDisableSanitizersByTagID: script.reduce((sanitizers, current) => {
      if (current?.hid) {
        sanitizers[current.hid] = ['innerHTML']
      }
      return sanitizers
    }, {})
  }
}
