import useRoundMoney from '~/lib/useRoundMoney'
import { RangeDiscountTypes } from '~/lib/enums'

export const getTotalNights = (financial) => {
  return financial?.TotalNights
}

export const getRenterTotalAmount = (financial) => {
  return financial?.RenterTotal
}

export const getPaidToReserveAmount = (financial) => {
  return Math.max(financial?.DepositAmount - financial?.CreditsApplied, 0)
}

export const getRenterServiceFee = (financial) => {
  return financial?.TotalRenterServiceFee
}

export const getOwnerServiceFee = (financial) => {
  return financial?.TotalOwnerServiceFee
}

export const getOwnerPayoutTotal = (financial) => {
  return financial?.OwnerPayoutTotal
}

export const getNightlyPrice = (financial) => {
  return useRoundMoney(financial?.TotalRentalAmount / financial?.TotalNights)
}

export const getTotalRentalAmount = (financial) => {
  return financial?.TotalRentalAmount
}

export const hasMidweekDiscount = (financial) => {
  return Boolean(financial?.Discounts?.Midweekdays)
}

export const getMidweekDiscount = (financial) => {
  return financial?.Discounts?.Midweekdays || null
}

export const getMidweekDiscountAmount = (financial) => {
  const discount = getMidweekDiscount(financial)
  return discount?.DiscountAmount || 0
}

export const getMidweekDiscountNumberOfNights = (financial) => {
  const discount = getMidweekDiscount(financial)
  return discount?.NumberOfNights || 0
}

export const hasDiscount = (financial) => {
  return Boolean(financial?.Nightly?.Discounted)
}

export const getNightlyRate = (financial) => {
  return financial?.Nightly.Discounted || financial?.Nightly.Base || null
}

export const getBaseNightlyRate = (financial) => {
  return financial?.Nightly?.Base || null
}

export const getBaseNightlyRateTotal = (financial) => {
  try {
    return getBaseNightlyRate(financial) * getTotalNights(financial)
  } catch {
    return null
  }
}

export const hasWeeklyDiscount = (financial) => {
  return Boolean(financial?.Discounts?.Weekly)
}

export const getWeeklyDiscountAmount = (financial) => {
  return financial?.Discounts?.Weekly?.DiscountAmount || null
}

export const hasMonthlyDiscount = (financial) => {
  return Boolean(financial?.Discounts?.Monthly)
}

export const getMonthlyDiscountAmount = (financial) => {
  return financial?.Discounts?.Monthly?.DiscountAmount || null
}

export const getPreDiscountTotal = (financial) => {
  return financial?.TotalPreDiscountRentalAmount
}

export const hasRangeDiscount = (financial) => {
  return Boolean(financial?.Discounts?.Monthly || financial?.Discounts?.Weekly)
}

export const getRangeDiscountType = (financial) => {
  if (hasMonthlyDiscount(financial)) {
    return RangeDiscountTypes.Monthly
  }

  if (hasWeeklyDiscount(financial)) {
    return RangeDiscountTypes.Weekly
  }
}

export const getTotalRenterServiceFee = (financial) => {
  return financial?.TotalRenterServiceFee
}

export const getTotalRenterTaxesAndFees = (financial) => {
  return (
    getTotalRenterServiceFee(financial) +
    financial.TotalRenterTaxes +
    financial.TotalOwnerSalesTaxes +
    financial.TotalCleanings +
    financial.TotalUSTaxes
  )
}

export const getStationaryDiscountPercentage = (financial) => {
  return financial?.NightlyInsuranceDiscountRateStationary * 100
}
