import dayjs from '~/lib/useDates'
import { RvUsageType } from '~/lib/enums'
import { rvUsageIsDelivery } from '../lib/bookings'

export const state = () => ({
  bookingPriceParams: {
    DateEnd: null,
    DateStart: null,
    OwnerId: null,
    RVId: null,
    PlaceOfResidence: 'XY',
    ProtectionLevel: null,
    RoadsideAssistanceState: true,
    ListAddOnPicked: [],
    Adults: 2,
    Children: 0,
    Pets: 0,
    CustomTotalRentalAmount: null,
    CustomTotalAddonAmount: null,
    CustomDeliveryAmountCents: null,
    RvUsage: RvUsageType.Pickup, // Only in place for old RTB to use get-booking-price
    ShowProtectionPlan: true
  },
  bookingPrice: null,
  rangeConflictError: false,
  bookingTimeError: false,
  bookingCheckInDayError: false,
  bookingCheckOutDayError: false,
  bookingLeadTimeError: false,
  rvSoldError: false,
  bookingPriceUnavailable: false,
  deliveryDistanceExceedsMaxError: false,
  noDeliveryDistanceFoundError: false,
  withDelivery: null,
  hasDelivery: null,
  destinationData: {
    destination: '',
    destinationLatitude: null,
    destinationLongitude: null,
    destinationTypes: null,
    destinationCountry: null,
    placeId: null
  },
  expandedView: false
})

export const actions = {
  async SET_BOOKING_PRICE_PARAMS({ commit }, params) {
    await commit('SET_BOOKING_PRICE_PARAMS', params)
  },

  async UPDATE_BOOKING_PRICE({ commit, state, dispatch }, params) {
    await commit('SET_BOOKING_PRICE_PARAMS', params)

    if (!state.bookingPriceParams.DateStart || !state.bookingPriceParams.DateEnd) {
      return
    }

    if (
      rvUsageIsDelivery(state.bookingPriceParams.RvUsage) &&
      !state.destinationData.destination &&
      !params?.Destination
    ) {
      return
    }

    try {
      commit('CLEAR_ERRORS')

      const priceParams = JSON.parse(JSON.stringify(state.bookingPriceParams))

      if (rvUsageIsDelivery(state.bookingPriceParams.RvUsage)) {
        priceParams.Destination = params?.Destination || state.destinationData.destination
        priceParams.DestinationLatitude = params?.DestinationLatitude || state.destinationData.destinationLatitude
        priceParams.DestinationLongitude = params?.DestinationLongitude || state.destinationData.destinationLongitude
      }

      const bookingPrice = await this.$axios.$post(
        `${this.$config.apiUrl}/api/v2/bookings/get-booking-price`,
        priceParams
      )

      commit('SET_BOOKING_PRICE', bookingPrice)
    } catch (err) {
      const errorCode = err?.response?.data?.Code

      const BOOKING_PRICE_ERRORS = {
        BOOKING_OVERLAPS_DATES: 'SET_RANGE_CONFLICT_ERROR',
        SET_RV_SOLD_ERROR: 'SET_RV_SOLD_ERROR',
        BOOKING_LEADTIME: 'SET_BOOKING_LEADTIME_ERROR',
        INVALID_DATE_CHECKIN: 'SET_BOOKING_CHECK_IN_ERROR',
        INVALID_DATE_CHECKOUT: 'SET_BOOKING_CHECK_OUT_ERROR',
        DELIVERY_DISTANCE_EXCEEDS_MAX: 'DELIVERY_DISTANCE_EXCEEDS_MAX_ERROR',
        NO_DELIVERY_DISTANCE_FOUND: 'NO_DELIVERY_DISTANCE_FOUND_ERROR'
      }

      if (BOOKING_PRICE_ERRORS[errorCode]) {
        commit(BOOKING_PRICE_ERRORS[errorCode], true)
      }

      if (errorCode === 'NO_DELIVERY_FOUND') {
        dispatch('UPDATE_BOOKING_PRICE', { RvUsage: RvUsageType.Pickup })
      }

      if (errorCode !== 'DELIVERY_DISTANCE_EXCEEDS_MAX') {
        commit('CLEAR_BOOKING_PRICE')
        commit('BOOKING_PRICE_UNAVAILABLE')
      }
    }
  },

  CLEAR_BOOKING_PRICE: ({ commit }) => {
    commit('CLEAR_BOOKING_PRICE')
  },

  SET_DELIVERY: ({ dispatch }, isDelivery) => {
    const RvUsage = isDelivery ? RvUsageType.Delivery : RvUsageType.Pickup
    dispatch('UPDATE_BOOKING_PRICE', { RvUsage })
  },

  SET_DESTINATION_DATA({ commit }, destinationData) {
    commit('SET_DESTINATION_DATA', destinationData)
  },

  SET_WITH_DELIVERY({ commit }, withDelivery) {
    commit('SET_WITH_DELIVERY', withDelivery)
  },

  RESET_DESTINATION_DATA({ commit }) {
    commit('RESET_DESTINATION_DATA')
  },

  SET_RV_USAGE({ commit }, rvUsage) {
    commit('SET_RV_USAGE', rvUsage)
  }
}

export const getters = {
  isInstabookAvailable: (state) => {
    return Boolean(state?.bookingPrice?.InstabookAvailable)
  },

  isReadyToBook:
    (state) =>
    ({ maxGuests = 2, minimumRentalDay = 1 }) => {
      if (!state?.bookingPrice?.Financial) return false

      const guestsCount = Number(state.bookingPriceParams.Adults) + Number(state.bookingPriceParams.Children)
      const isValidDates =
        dayjs(state.bookingPriceParams.DateStart).isValid() && dayjs(state.bookingPriceParams.DateEnd).isValid()

      const validUsage = Object.values(RvUsageType).includes(state.bookingPriceParams.RvUsage)
      return Boolean(
        isValidDates &&
          state.bookingPrice.Financial.TotalNights >= minimumRentalDay &&
          guestsCount <= maxGuests &&
          validUsage
      )
    },

  startDate: (state) => state.bookingPriceParams.DateStart,

  endDate: (state) => state.bookingPriceParams.DateEnd,

  hasError: (state) =>
    Boolean(
      state.rangeConflictError ||
        state.bookingLeadTimeError ||
        state.bookingTimeError ||
        state.rvSoldError ||
        state.bookingCheckInDayError ||
        state.bookingCheckOutDayError ||
        state.deliveryDistanceExceedsMaxError
    )
}

export const mutations = {
  SET_BOOKING_PRICE_PARAMS(state, payload) {
    if (payload) {
      Object.entries(payload).forEach(([key, value]) => {
        state.bookingPriceParams[key] = value
      })
    }

    const { RVId, DateStart, DateEnd, Adults, Children, Pets } = state.bookingPriceParams

    if (RVId) {
      if (this.$cookies.get(RVId)) {
        this.$cookies.remove(RVId, {
          maxAge: 300,
          path: '/'
        })
      }
      if (DateStart && DateEnd) {
        this.$cookies.set(
          RVId,
          {
            DateStart,
            DateEnd,
            Adults,
            Children,
            Pets,
            Delivery: state.withDelivery || null
          },
          {
            maxAge: 300,
            path: '/'
          }
        )
      }
    }
  },

  CLEAR_BOOKING_PRICE(state) {
    state.bookingPrice = null
  },

  CLEAR_ERRORS(state) {
    state.rangeConflictError = false
    state.bookingTimeError = false
    state.rvSoldError = false
    state.bookingLeadTimeError = false
    state.bookingCheckInDayError = false
    state.bookingCheckOutDayError = false
    state.deliveryDistanceExceedsMaxError = false
  },

  SET_BOOKING_PRICE(state, bookingPrice) {
    state.bookingPrice = bookingPrice
    state.bookingPriceUnavailable = false
  },

  SET_RANGE_CONFLICT_ERROR(state, rangeConflictError) {
    state.rangeConflictError = rangeConflictError
  },

  SET_BOOKING_TIME_ERROR(state, bookingTimeError) {
    state.bookingTimeError = bookingTimeError
  },

  SET_BOOKING_LEADTIME_ERROR(state, leadTimeError) {
    state.bookingLeadTimeError = leadTimeError
  },

  SET_BOOKING_CHECK_IN_ERROR(state, checkInError) {
    state.bookingCheckInDayError = checkInError
  },

  SET_BOOKING_CHECK_OUT_ERROR(state, checkOutError) {
    state.bookingCheckOutDayError = checkOutError
  },

  SET_RV_SOLD_ERROR(state, rvSoldError) {
    state.rvSoldError = rvSoldError
  },

  DELIVERY_DISTANCE_EXCEEDS_MAX_ERROR(state, deliveryDistanceExceedsMaxError) {
    state.deliveryDistanceExceedsMaxError = deliveryDistanceExceedsMaxError
  },

  NO_DELIVERY_DISTANCE_FOUND_ERROR(state, noDeliveryDistanceFoundError) {
    state.noDeliveryDistanceFoundError = noDeliveryDistanceFoundError
  },

  BOOKING_PRICE_UNAVAILABLE(state) {
    state.bookingPriceUnavailable = true
  },

  SET_WITH_DELIVERY(state, withDelivery = null) {
    state.withDelivery = withDelivery
  },

  SET_RV_USAGE(state, rvUsage) {
    state.bookingPriceParams.RvUsage = rvUsage
  },

  SET_DESTINATION_DATA(state, destinationData = null) {
    state.destinationData = JSON.parse(JSON.stringify(destinationData))
  },

  RESET_DESTINATION_DATA(state) {
    state.destinationData = {
      destination: '',
      destinationLatitude: null,
      destinationLongitude: null,
      destinationTypes: null,
      destinationCountry: null,
      placeId: null
    }
  }
}
