
import routeMixin from '~/mixins/route'
import { handleExitIntent } from '~/lib/exit-intent'

export default {
  mixins: [routeMixin],

  data() {
    return {
      hasRVezyNotifications: false,
      inboxNotificationsCount: 0,
      navExpanded: false,
      showSearchForm: true,
      hideHeader: false
    }
  },

  computed: {
    showLocationSearch() {
      if (this.isRequestToBookFlow) {
        return false
      }

      if (
        this.getRouteBaseName() === 'payment-callback' ||
        (this.getRouteBaseName() === 'superhost' && this.$device.isMobileOrTablet) ||
        (this.getRouteBaseName()?.includes('dashboard') && !this.$mediaQueries.isLargeBreakpoint)
      ) {
        return false
      }

      return true
    },

    isRequestToBookFlow() {
      return [
        'rv-rental-request-to-book-alias',
        'rv-rental-request-to-book-alias-additionals',
        'dashboard-bookings-id-payment'
      ].includes(this.routeBaseName)
    },

    showDashboardNav() {
      return (
        this.routeBaseName.startsWith('dashboard') &&
        !/^dashboard-bookings-(pending|payment|paymentSuccess)$/.test(this.routeBaseName)
      )
    },

    shouldShowMyRVezyPushNote() {
      return this.hasRVezyNotifications || (this.$auth.user && !this.$auth.user.PhoneNumber)
    }
  },

  watch: {
    '$auth.loggedIn': {
      handler: function (newVal, oldVal) {
        if (newVal || typeof oldVal !== 'undefined') {
          this.checkUserPendingNotifications()
        } else if (!newVal) {
          this.resetUserPendingNotifications()
        }
      },

      immediate: true
    },

    navExpanded: {
      handler: function (newVal) {
        if (this.$isServer) {
          return
        }

        if (newVal) {
          document?.body.classList.add('body-lock')
        } else {
          document?.body.classList.remove('body-lock')
        }
      },

      immediate: true
    }
  },

  mounted() {
    this.$nuxt.$on('show-header-search-form', this.showHeaderSearchForm)
    handleExitIntent(this.$auth)
  },

  beforeDestroy() {
    this.$nuxt.$off('show-header-search-form', this.showHeaderSearchForm)
  },

  methods: {
    onScroll($event) {
      this.hideHeader = Boolean(
        $event?.target?.scrollingElement?.scrollTop > 0 && !this.$mediaQueries.isMediumBreakpoint
      )
    },

    showHeaderSearchForm(visible) {
      this.showSearchForm = visible
    },

    async checkUserPendingNotifications() {
      if (this.$auth.loggedIn) {
        const model = await this.$axios.$get(`${this.$config.apiUrl}/api/Message/user-pending-notifications-indicator`)

        this.hasRVezyNotifications = model.HasRVezyNotifications
        this.inboxNotificationsCount = model.TotalInboxNotifications
      } else {
        this.resetUserPendingNotifications()
      }
    },

    resetUserPendingNotifications() {
      this.hasRVezyNotifications = false
      this.inboxNotificationsCount = 0
    }
  }
}
