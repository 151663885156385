
export default {
  data() {
    return {
      mobileSearchFormLoaded: false,
      showMobileSearchForm: false
    }
  },

  methods: {
    openMobileSearch() {
      this.mobileSearchFormLoaded = true
      this.showMobileSearchForm = !this.showMobileSearchForm
    }
  }
}
