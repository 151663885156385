import { ProtectionLevelMapping } from '@/constants'
import { ChangeRequestType } from '@/lib/enums'
import { changeRequestItems } from '@/lib/addons'

const changeRequestItemsDefault = { AddAddOns: [], RemoveAddOns: [] }
const addonsWereModified = (state) => {
  var addOns = state.selectedAddOns.map(({ Name }) => Name)
  var originalAddons = state.originalListOfAddons.map(({ Name }) => Name)
  if (addOns.length !== originalAddons.length) return true

  return addOns.some((element) => {
    if (!originalAddons.includes(element)) return true
    return false
  })
}

export const state = () => ({
  initialized: false,
  isQuoteLoading: false,
  changeType: null, // Should become obsolete soon
  quoteError: '',
  details: {
    adults: 2,
    children: 0,
    pets: 0
  },
  startDate: null,
  endDate: null,
  rvSnapshot: null,
  financialData: null,
  rv: null,
  changeRequestItems: changeRequestItemsDefault,
  selectedAddOns: [],
  originalListOfAddons: [],
  rvUsage: null,
  insuranceProtectionLevel: null,
  destinationData: {
    destination: '',
    destinationLatitude: null,
    destinationLongitude: null,
    destinationTypes: null,
    destinationCountry: null,
    placeId: null
  }
})

export const actions = {
  async SET_DATES_CHANGE_TYPE({ commit }) {
    commit('SET_CHANGE_TYPE', ChangeRequestType.Dates)
  },

  async SET_DETAILS_CHANGE_TYPE({ commit }) {
    commit('SET_CHANGE_TYPE', ChangeRequestType.Details)
  },

  async SET_DELIVERY_CHANGE_TYPE({ commit }) {
    commit('SET_CHANGE_TYPE', ChangeRequestType.Delivery)
  },

  async SET_ADDONS_CHANGE_TYPE({ commit }) {
    commit('SET_CHANGE_TYPE', ChangeRequestType.Addons)
  },

  async ADD_ADDON_QUOTE({ commit }, addOn) {
    commit('SET_SELECTED_ADDON', addOn)
  },

  async REMOVE_ADDON_QUOTE({ commit }, addOn) {
    commit('REMOVE_SELECTED_ADDON', addOn)
  },

  async RESET_CHANGE_REQUEST_ITEMS({ commit }) {
    commit('CLEAR_CHANGE_REQUEST_ITEMS')
  },

  async SET_DESTINATION_DATA({ commit }, destinationData) {
    commit('SET_DESTINATION_DATA', destinationData)
  },

  async RESET_DESTINATION_DATA({ commit }) {
    commit('RESET_DESTINATION_DATA')
  },

  async SET_RV_USAGE({ commit }, rvUsage) {
    commit('SET_RV_USAGE', rvUsage)
  },

  async RESET_RV_USAGE({ commit }) {
    commit('RESET_RV_USAGE')
  },

  async SET_INSURANCE_PROTECTION_LEVEL({ commit }, protectionLevel) {
    commit('SET_INSURANCE_PROTECTION_LEVEL', protectionLevel)
  },

  async GET_CHANGE_REQUEST_DRAFT({ commit, state }) {
    try {
      commit('SET_LOADING', true)
      commit('CLEAR_CHANGE_REQUEST_ITEMS')
      commit('SET_MANAGE_REQUEST_ITEMS')

      const { Financial, ChangeRequestItems } = await this.$axios.$put(
        `${this.$config.apiUrl}/api/v2/bookings/${state.financialData.BookingId}/change-request/draft`,
        {
          Adults: state.details.adults,
          Children: state.details.children,
          Pets: state.details.pets ? 1 : 0,
          StartDate: state.startDate,
          EndDate: state.endDate,
          RvUsage: state.rvUsage,
          InsuranceProtectionLevel: state.financialData.InsuranceProtectionLevel,
          Destination: state.destinationData.destination,
          DestinationLatitude: state.destinationData.destinationLatitude,
          DestinationLongitude: state.destinationData.destinationLongitude,
          ChangeRequestItems: state.changeRequestItems
        }
      )

      commit('SET_CHANGE_REQUEST_ITEMS_ADDONS', ChangeRequestItems)
      commit('SET_FINANCIAL_DATA', Financial)
    } catch (err) {
      if (err && err.response) {
        commit('SET_ERROR', err.response.data)
      }

      throw err
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async APPLY_CHANGES({ commit, state }, isOwner = false) {
    try {
      commit('SET_LOADING', true)
      const endpointPath = isOwner ? 'owner-change-request' : 'renter-change-request'
      await this.$axios.$post(
        `${this.$config.apiUrl}/api/v2/bookings/${state.financialData.BookingId}/${endpointPath}`,
        {
          Adults: state.details.adults,
          Children: state.details.children,
          Pets: state.details.pets ? 1 : 0,
          StartDate: state.startDate,
          EndDate: state.endDate,
          ChangeRequestItems: state.changeRequestItems,
          RvUsage: state.rvUsage,
          InsuranceProtectionLevel: state.financialData?.InsuranceProtectionLevel,
          Destination: state.destinationData.destination,
          DestinationLatitude: state.destinationData.destinationLatitude,
          DestinationLongitude: state.destinationData.destinationLongitude
        }
      )
      commit('SET_CHANGE_REQUEST_ITEMS', changeRequestItemsDefault)
    } catch (err) {
      if (err && err.response) {
        commit('SET_ERROR', err.response.data)
      }

      throw err
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

export const getters = {
  isInitialized: (state) => state.initialized,
  changeType: (state) => state.changeType,
  hasChanges:
    (state) =>
    (booking, bookingStartDate = null, bookingEndDate = null) => {
      if (state.details.adults !== booking.Adults) {
        return true
      } else if (state.details.children !== booking.Children) {
        return true
      } else if (state.details.pets !== booking.Pets) {
        return true
      } else if (state.rvUsage && state.rvUsage !== booking.RvUsage) {
        return true
      } else if (state.destinationData.destination && state.destinationData.destination !== booking.Destination) {
        return true
      } else if (
        state.financialData &&
        state.financialData.InsuranceProtectionLevel &&
        booking.ProtectionLevel &&
        state.financialData.InsuranceProtectionLevel.toLowerCase() !== booking.ProtectionLevel.toLowerCase()
      ) {
        return true
      } else if (addonsWereModified(state)) {
        return true
      } else if (
        (bookingStartDate && state.startDate && state.startDate !== bookingStartDate) ||
        (bookingEndDate && state.endDate && state.endDate !== bookingEndDate)
      ) {
        return true
      }

      return false
    },
  addonsHasNoChanges: (state) => {
    const selectedItems = state.selectedAddOns
    const originalItems = state.originalListOfAddons?.map((y) => y?.Name)

    if (selectedItems?.length !== originalItems?.length) return false

    return selectedItems
      ?.filter((z) => z !== null)
      ?.map((x) => x?.Name)
      ?.every((item) => {
        return Boolean(originalItems.includes(item))
      })
  }
}

export const mutations = {
  INITIALIZE(state, { booking, rv }) {
    state.initialized = true
    state.details = {
      adults: booking.Adults,
      children: booking.Children,
      pets: booking.Pets || 0
    }
    state.startDate = this.$moment.utc(booking.DateStart).format('YYYY-MM-DD')
    state.endDate = this.$moment.utc(booking.DateEnd).format('YYYY-MM-DD')
    state.rvSnapshot = booking.RVSnapshot
    state.financialData = booking.Financial
    state.rv = rv
    state.changeRequestItems = changeRequestItemsDefault
    state.selectedAddOns = [...state.financialData.AddOns]
    state.originalListOfAddons = [...state.financialData.AddOns]
    state.rvUsage = booking.RvUsage
    state.destinationData.destination = booking.Destination
    state.destinationData.destinationLatitude = booking.DestinationLatitude
    state.destinationData.destinationLongitude = booking.DestinationLongitude
    state.destinationData.destinationCountry = null
  },

  SET_CHANGE_TYPE(state, changeType) {
    state.changeType = changeType
  },

  RESET_CHANGE_TYPE(state) {
    state.changeType = null
  },

  CLEAR_ALL(state) {
    state.initialized = false
    state.isQuoteLoading = false
    state.details = {
      adults: 2,
      children: 0,
      pets: 0
    }
    state.changeType = null
    state.rvSnapshot = null
    state.financialData = null
    state.rv = null
    state.changeRequestItems = changeRequestItemsDefault
    state.selectedAddOns = []
    state.originalListOfAddons = []
    state.rvUsage = null
    state.destinationData.destination = null
    state.destinationData.destinationLatitude = null
    state.destinationData.destinationLongitude = null
    state.destinationData.destinationCountry = null
  },

  SET_LOADING(state, value) {
    state.isQuoteLoading = value
  },

  SET_ERROR(state, value) {
    state.quoteError = value.Message || value.Code || 'Unknown error'
  },

  SET_RV_USAGE(state, rvUsage) {
    state.rvUsage = rvUsage
  },

  RESET_RV_USAGE(state) {
    state.rvUsage = null
  },

  SET_INSURANCE_PROTECTION_LEVEL(state, insuranceProtectionLevel) {
    state.financialData.InsuranceProtectionLevel = ProtectionLevelMapping[insuranceProtectionLevel]
  },

  SET_DESTINATION_DATA(state, destinationData = null) {
    state.destinationData = JSON.parse(JSON.stringify(destinationData))
  },

  RESET_DESTINATION_DATA(state) {
    state.destinationData = {}
  },

  SET_FINANCIAL_DATA(state, financialData) {
    state.financialData = financialData
  },
  CLEAN_SELECTED_ADDONS_LIST(state) {
    state.selectedAddOns = []
  },
  SET_SELECTED_ADDON(state, addOn) {
    state.selectedAddOns.push(addOn)
  },
  REMOVE_SELECTED_ADDON(state, addOn) {
    const index = state.selectedAddOns.findIndex((selectedAddon) => {
      return selectedAddon.Slug === addOn.Slug && selectedAddon.Name === addOn.Name
    })
    if (index >= 0) {
      state.selectedAddOns.splice(index, 1)
    }
  },
  SET_ADD_ADDON(state) {
    state.changeRequestItems.AddAddOns = state.selectedAddOns.filter(
      (addons) => !state.financialData.AddOns.includes(addons)
    )
  },
  SET_REMOVE_ADDON(state) {
    state.changeRequestItems.RemoveAddOns = state.financialData.AddOns.filter(
      (addons) => !state.selectedAddOns.includes(addons)
    )
  },
  SET_CHANGE_REQUEST_ITEMS(state, changeRequestItems) {
    // clean arrays
    if (changeRequestItems.RemoveAddOns.length) {
      let removeIndex = changeRequestItems.RemoveAddOns.length
      // loop through remove addons
      while (removeIndex--) {
        // loop through add addons and find matching addon
        const foundIndex = changeRequestItems.AddAddOns.findIndex((addAddOn) => {
          if (addAddOn.Slug) {
            return changeRequestItems.RemoveAddOns[removeIndex].Slug === addAddOn.Slug
          }
          return changeRequestItems.RemoveAddOns[removeIndex].AddOnType === addAddOn.AddOnType
        })

        if (foundIndex >= 0) {
          // remove opposite change request items
          changeRequestItems.AddAddOns.splice(foundIndex, 1)
          changeRequestItems.RemoveAddOns.splice(removeIndex, 1)
        }
      }
    }

    // remove duplicates
    changeRequestItems.AddAddOns = changeRequestItems.AddAddOns.filter(
      (addOn, index, array) => array.findIndex((t) => t.Slug === addOn.Slug && t.Name === addOn.Name) === index
    )
    changeRequestItems.RemoveAddOns = changeRequestItems.RemoveAddOns.filter(
      (addOn, index, array) => array.findIndex((t) => t.Slug === addOn.Slug && t.Name === addOn.Name) === index
    )

    state.changeRequestItems = changeRequestItems
  },

  SET_CHANGE_REQUEST_ITEMS_ADDONS(state, changeRequestItems) {
    state.changeRequestItems = changeRequestItems
  },

  SET_DETAILS(state, params) {
    Object.entries(params).forEach(([key, value]) => {
      state.details[key] = value
    })
  },

  SET_PETS(state, params) {
    Object.entries(params).forEach(([key, value]) => {
      state.details[key] = value
    })
  },

  SET_DATES(state, { startDate, endDate }) {
    state.startDate = startDate
    state.endDate = endDate
  },

  CLEAR_CHANGE_REQUEST_ITEMS(state) {
    state.changeRequestItems = { AddAddOns: [], RemoveAddOns: [] }
  },

  SET_MANAGE_REQUEST_ITEMS(state) {
    const selectedItems = changeRequestItems(state.selectedAddOns)
    const originalItems = changeRequestItems(state.originalListOfAddons)

    state.changeRequestItems.AddAddOns = selectedItems.filter(
      (x) => !originalItems.map((y) => y?.Name).includes(x.Name)
    )

    originalItems.forEach((item) => {
      if (!selectedItems.map((z) => z?.Name).includes(item?.Name)) {
        state.changeRequestItems.RemoveAddOns.push(item)
      }
    })
  }
}
