export default function ({ $config, $i18n }) {
  const campgroundI18nLink = `${$config.apps.campgrounds.baseUrl}/${$i18n.locale === 'fr' ? 'fr/' : ''}`

  function getCampgroundsUrl(slug) {
    const slugPath = slug ? `/${slug}` : ''
    return `${campgroundI18nLink}campgrounds${slugPath}`
  }

  return {
    getCampgroundsUrl
  }
}
