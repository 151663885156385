const middleware = {}

middleware['clarityMiddleware'] = require('../middleware/clarityMiddleware.js')
middleware['clarityMiddleware'] = middleware['clarityMiddleware'].default || middleware['clarityMiddleware']

middleware['maintenanceMode'] = require('../middleware/maintenanceMode.js')
middleware['maintenanceMode'] = middleware['maintenanceMode'].default || middleware['maintenanceMode']

middleware['modals'] = require('../middleware/modals.js')
middleware['modals'] = middleware['modals'].default || middleware['modals']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['searchParameters'] = require('../middleware/searchParameters.js')
middleware['searchParameters'] = middleware['searchParameters'].default || middleware['searchParameters']

middleware['setSessionInfo'] = require('../middleware/setSessionInfo.js')
middleware['setSessionInfo'] = middleware['setSessionInfo'].default || middleware['setSessionInfo']

export default middleware
