import {
  extractSearchQuery,
  queryStringToBounds,
  queryStringToDates,
  queryStringToGuests,
  queryStringToSearchFilters
} from '~/lib/search/parameters.js'
import { parseGoogleGeocode } from '~/lib/search/google.js'
import { logDev } from '~/lib/dev-logging.js'

export default async function ({ from, route, $search, $geocode, getRouteBaseName, $cookies }) {
  if (process.server || getRouteBaseName(from) === getRouteBaseName(route)) {
    return
  }

  const searchQuery = extractSearchQuery(route.query)

  const bounds = queryStringToBounds(searchQuery)
  const { dates } = queryStringToDates(searchQuery)
  const guests = queryStringToGuests(searchQuery)
  const filters = queryStringToSearchFilters(searchQuery)

  if (bounds?.hasBounds) {
    $search.updateBounds(bounds)
  } else if (searchQuery.SearchAddress && searchQuery.SearchAddress !== $search.parameters.location.fullName) {
    logDev({ domain: 'middleware', func: 'searchParameters', message: 'geocode', color: 'orange' })
    const geocodedAddress = await $geocode(searchQuery.SearchAddress)
    if (geocodedAddress) {
      const location = parseGoogleGeocode({ fullName: searchQuery.SearchAddress, geocode: geocodedAddress })
      $search.updateLocationFields(location)
    }
  } else {
    const searchLocationCookie = $cookies.get('search_location')
    if (searchLocationCookie?.searchable) {
      logDev({ domain: 'middleware', func: 'searchParameters', message: 'cookie', color: 'orange' })
      $search.updateLocationFields(searchLocationCookie)
    }
  }

  $search.updateDates({ ...dates })
  $search.updateGuests(guests)
  $search.updateFilters(filters)
}
