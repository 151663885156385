import { render, staticRenderFns } from "./new-rv-footer.vue?vue&type=template&id=763ad0e2&scoped=true"
import script from "./new-rv-footer.vue?vue&type=script&lang=js"
export * from "./new-rv-footer.vue?vue&type=script&lang=js"
import style0 from "./new-rv-footer.vue?vue&type=style&index=0&id=763ad0e2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "763ad0e2",
  null
  
)

/* custom blocks */
import block0 from "./new-rv-footer.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZButton: require('/home/vsts/work/1/s/components/style-guide/button.vue').default,ButtonNeedHelp: require('/home/vsts/work/1/s/components/button/need-help.vue').default,ZContainer: require('/home/vsts/work/1/s/components/style-guide/container.vue').default,Footer: require('/home/vsts/work/1/s/components/app/footer.vue').default})
