export default {
  common: {
    rv: 'RV',
    moneyDisplay: '${amount}',
    dateFormat: {
      short: 'M/DD',
      medium: 'MMM D'
    },
    yes: 'Yes',
    no: 'No'
  },
  actions: {
    back: 'Back',
    bookNow: 'Book Now',
    cancel: 'Cancel',
    continue: 'Continue',
    done: 'Done',
    edit: 'Edit',
    editProfile: 'Edit profile',
    goBack: 'Go back',
    goToDashboard: 'Go to Dashboard',
    hideBenefits: 'Hide Benefits',
    learnMore: 'Learn more',
    login: 'Login',
    logOut: 'Log out',
    loadMorePosts: 'Load more posts',
    moreInfo: 'More info',
    next: 'Next',
    prev: 'Prev',
    readMore: 'Read more',
    readLess: 'Read less',
    remove: 'Remove',
    requestToBook: 'Request to book',
    save: 'Save',
    saveChanges: 'Save changes',
    saveAndExit: 'Save and exit',
    search: 'Search',
    select: 'Select',
    share: 'Share',
    showBenefits: 'Show Benefits',
    showMore: 'Show more',
    showLess: 'Show less',
    showMeMore: 'Show me more',
    submit: 'Submit',
    update: 'Update',
    submitReview: 'Submit review',
    verify: 'Verify',
    recommended: 'Recommended'
  },
  language: {
    english: 'English',
    french: 'French'
  },
  errors: {
    required: 'This field is required',
    serverErrors: 'Looks like there was an issue in submitting your information. Please try again later.',
    invalidEmailAddress: 'Invalid email address',
    emailTaken: 'This email is already in use.',
    invalidPhoneNumber: 'Invalid phone number',
    samePhoneNumber: 'Same phone number as primary phone number',
    invalidPostalZip: 'Invalid postal/ZIP code',
    invalidField: 'Invalid field value',
    minimumLength: 'Minimum of {length} characters.',
    maximumLength: 'Maximum of {length} characters.',
    minimumValue: 'Minimum value of {value}.',
    minimumPerNight: 'Minimum of ${value} per night',
    minimumAmountAndPerNight: 'Minimum of ${value} (${nightlyPrice} per night)',
    maximumValue: 'Maximum value of {value}',
    passwordDoesntMatch: "Password doesn't match",
    noSimilarRvsFound: 'No similar RVs found',
    pleaseSelectDate: 'Please select a date',
    imageSizeError: 'Your image exceeded the maximum upload size of {size}.',
    fileExtensionError: 'Only {extensions} files are allowed.',
    invalidUrl: 'Invalid URL'
  },
  new: 'New',
  hours: '1 hour | {n} hours',
  included: 'Included',
  unlimited: 'Unlimited',
  optional: 'Optional',
  day: 'day | days',
  typeSomething: 'Type something',

  select: {
    select: 'Select',
    country: '@:select.select a country',
    region: '@:select.select a region',
    province: '@:select.select a Province',
    state: '@:select.select a State',
    language: '@:select.select a language',
    year: '@:select.select a year',
    month: '@:select.select a month',
    day: '@:select.select a day',
    reason: '@:select.select a reason'
  },

  booking: {
    closeout: {
      closoutChargesTooGreat:
        'Closeout charges amount can\'t be greater than ({amount}), please <a href="https://help.rvezy.com/hc/en-us/requests/new" target="_blank">contact support</a>'
    },
    /* cancel is problematic with yaml loader */
    cancel: {
      title: 'Are you sure?',
      description:
        'Canceling affects you and your guests. You may be subject to pay a fee, the dates will be blocked in your calendar, and your RV’s placement in search may be lowered.',
      cancellationPolicy: 'Cancellation policy',
      cancelBooking: 'Cancel booking',
      cancelBookingSelling: 'Cancel booking | Cancel booking | Cancel {n} bookings',

      owner: {
        learnMoreURL:
          'https://help.rvezy.com/hc/en-us/articles/13441962169115-How-can-I-apply-to-remove-cancellation-penalties-',

        error: {
          title: 'Oops, something went wrong',
          content: 'We encountered a problem when trying to cancel your booking(s). Please {0}.',
          contactSupport: 'contact support'
        },

        canceled: {
          title: 'Booking canceled',
          success: 'This booking has been canceled.',
          content: 'Your RV’s position in search results has been temporarily lowered.',
          content_calendar:
            'Your calendar has been blocked between {startDate} and {endDate}. You will not be able to book another trip during that period.',

          selling: {
            title: 'Thank you for listing with RVezy.',
            content: 'Your RV will be deleted {date}.'
          },

          additionalBookings: {
            title: 'Additional bookings were canceled.',
            text: 'One conflicting booking with a total payout of {payoutTotal} was canceled. | {n} conflicting bookings with a total payout of {payoutTotal} were also canceled.'
          }
        },

        confirm: {
          title: '${amount} will be deducted from your next payout.',
          learnMore: 'Learn more',
          learnMoreURL:
            'https://help.rvezy.com/hc/en-us/articles/13562362996891-What-is-the-cancellation-policy-for-RV-hosts-'
        },

        'confirm-selling': {
          title: 'You’re about to cancel',
          rvWillBeDeleted: 'Your RV will be deleted on {date}.',
          additionalBookingsWillBeCanceled: {
            title: 'Additional bookings will be automatically canceled.',
            text: 'One conflicting booking with a total payout of {payoutTotal} will automatically be canceled. | {n} conflicting bookings with a total payout of {payoutTotal} will automatically be canceled.'
          }
        },

        message: {
          title: 'Message the renter',
          label1: 'Let {firstName} know why you’re canceling. | Let them know why you’re canceling',
          label2: 'Send them a private message explaining the circumstances.',
          placeholder: 'Sorry, but I have to cancel because...',
          placeholderError: 'Add a message for {firstName} | Add a message for the renters',
          required: 'Please add a message',
          minimumLength: 'Please type at least {length} characters'
        },

        calendar: {
          title: 'When are you selling the RV?',
          selectADate: 'Select a date',
          selectDate: 'Select {date}'
        },

        'guest-cancel': {
          title: 'Ask your guest to cancel instead'
        },

        form: {
          whyAreYouCanceling: 'Why are you canceling?*',
          placeholder: 'Select reason',
          required: 'Please select a reason for canceling',
          reasonForCancelingTheBooking: 'Reason for canceling the booking',
          MyRvIsNotAvailableForRentOnTheRequestedDates: {
            label: 'Reason for RV unavailability*',
            other: 'Reason for RV unavailability'
          },
          HaveConcernsWithThisRenter: {
            label: 'Why do you have concerns with the renter?*',
            other: 'Reason why you have concerns with the renter'
          },
          MyRvIsNotFitForTheRoad: {
            label: 'Why is your RV not fit for the road?*',
            other: 'Reason why your RV is not fit for the road'
          },
          ThisRvHasBeenSoldOrWillBeSold: {
            label: 'When are you selling the RV?*'
          },
          ExtenuatingCircumstances: {
            label: 'What are the extenuating circumstances?*',
            other: 'Reason for extenuating circumstances'
          },
          other: 'Reason for canceling the booking'
        },

        lostPayout: 'lost payout',

        pleaseExplain: {
          label: 'Please explain*',
          required: 'Please type a reason'
        },

        consequences: {
          affectedavailability: {
            title: "RV's availability will be affected.",
            content: "By canceling, you'll block the dates from {startDate} to {endDate} on your calendar."
          },

          default: {
            title: 'Canceling will impact your account status.',
            calendar: 'calendar',
            list: {
              0: 'Canceling temporarily lowers your RV’s position in search results.',
              1: 'The dates of the booking will be blocked on your {0} and you will not be able to book another trip during that period.'
            }
          },

          concerns: {
            title: 'Please only accept bookings you are confident in',
            list: {
              1: 'Please discuss trip details with renters prior to bookings in order to avoid canceling.'
            }
          }
        },
        keepReservation: 'Keep reservation',
        cancelReservation: 'Cancel reservation'
      },
      renter: {
        youHavePaid: "You've paid",
        yourRefund: 'Your refund',
        paidToDate: 'Paid to date',
        nonRefundable: 'Non-refundable',
        nightlyRate: 'Nightly rate x {nights}',
        ownerSalesTax: 'Owner sales tax',
        serviceFee: 'Service fee',
        tax: 'Tax',
        totalRefund: 'Total refund',
        whyDoYouNeedToCancel: 'Why do you need to cancel?*',
        reasonForCancellingTheBooking: 'Reason for cancelling the booking',
        reasons: {
          PersonalOrFamilyEmergency: 'Personal or family emergency',
          COVID: 'COVID-19',
          AvailabilityOrChangeOfPlans: 'Change of plans/no longer going on the trip',
          LackOfFunds: 'Booking is too expensive',
          UnresponsiveOwner: 'Owner is unresponsive',
          WeatherOrTravelRestrictions: 'Weather or travel restrictions',
          Other: 'Other'
        },
        creditsRefunded: '{amount} RVezy credits',
        yourCreditsWillReturnToYourAccount: 'Your RVezy credits will return to your account.',
        describeWhyYouAreCancelling: "Describe why you're cancelling"
      },
      riskScore: {
        description: {
          renter:
            'A combination of safety risks suggested that this booking may present higher safety risks, so it’s been canceled. A full refund has been issued to your payment method and should be received within three to five business days.',
          owner:
            'We detected higher safety risks for this guest, so this booking has been canceled for the safety of your RV.'
        }
      }
    },

    details: {
      /* do not remove, used in js file */
      checklist: {
        preArrival: 'Pre-Arrival',
        departure: 'Departure',
        return: 'Return',
        empty: 'Empty',
        full: 'Full',
        odometer: 'Odometer',
        fuel: 'Fuel',
        generator: 'Generator',
        propane: 'Propane',
        inspectionResults: 'Inspections',
        mileageIncluded: '{mileage} {unit}/day included. {value}/extra {unit}.',
        generatorIncluded: '{hours} hours/day included. {value}/extra hour.',
        damageChargesDescription: 'Must be less than the security deposit.',
        otherChargesDescription: 'For any other unforeseen expenses.'
      }
    }
  },

  ratingCategories: {
    ListingAccuracy: 'Accuracy',
    Value: 'Value',
    Cleanliness: 'Cleanliness',
    Communication: 'Communication'
  },

  user: {
    firstName: 'First Name',
    lastName: 'Last Name',
    phoneNumber: 'Mobile Phone Number',
    alternatePhoneNumber: 'Secondary Phone Number',
    email: 'Email',
    password: 'Password',

    position: {
      superOwner: 'Super Owner',
      owner: 'Owner',
      renter: 'Renter',
      superRenter: 'Super Renter'
    }
  },

  rvType: {
    text: 'RV Type',
    rvTypes: 'RV Types',
    iconAlt: '`RVezy {type} {rentalType} Icon',
    ClassA: 'Class A',
    ClassB: 'Class B',
    ClassC: 'Class C',
    TruckCamper: 'Truck Camper',
    Campervan: 'Campervan',

    FifthWheel: 'Fifth Wheel',
    Trailer: 'Trailer',
    TravelTrailer: 'Travel Trailer',
    SubTravelTrailer: 'Travel Trailer',
    TentTrailer: 'Tent Trailer',
    Hybrid: 'Hybrid',
    ToyHauler: 'Toy Hauler',
    MicroTrailer: 'Micro Trailer',
    Motorhome: 'Motorhome',
    MotorHome: 'Motorhome',
    RVCottage: 'RV Cottage',

    rvCottage: 'RV Cottage'
  },

  rv: {
    hitchWeight: 'Hitch Weight',
    hitchSize: 'Hitch Size',
    length: 'Length(ft)',
    sleeps: 'Sleeps',
    slideouts: '# of slide outs',
    weight: 'Weight',

    preferences: {
      experiencedDriverRequired: 'Experienced driver required',
      towingExperienceRequired: 'Towing experience required',
      noFestivalsOrEvents: 'No festivals or events',
      noCrossBorderTravel: 'No cross-border travel allowed',
      noPetsAllowed: 'No pets'
    },

    noDeliveryDistanceFound: {
      warningText: 'The RV cannot be delivered to this address. You can message the host to discuss delivery options.'
    },

    crossBorderTravel: {
      warning: {
        text: 'Crossing the border of {country} is not allowed for this RV.',
        description: 'Unfortunately, you don’t meet the host’s requirements to book this RV.',
        descriptionButton:
          'Unfortunately, you don’t meet the host’s requirements to book this RV. Let’s find an RV suitable for your trip!',
        countries: {
          CA: 'Canada',
          US: 'the United States',
          default: 'the country'
        }
      }
    }
  },

  faqs: {
    heading: 'Frequently asked questions'
  },

  pageLink: {
    aboutRVezy: 'About RVezy',
    account: 'Account',
    blog: 'Blog',
    calendar: 'Calendar',
    cancellationPolicy: 'Cancellation policy',
    contactUs: 'Contact us',
    dashboard: 'Dashboard',
    favourites: 'Favorites',
    helpCenter: 'Help center',
    help: 'Help',
    home: 'Home',
    howRVezyWorks: 'How RVezy Works',
    inbox: 'Inbox',
    myRvezy: 'My RVezy',
    listAnRV: 'List an RV',
    privacyPolicy: 'Privacy Policy',
    profile: 'Profile',
    rvRentals: 'RV rentals',
    searchRVs: 'Search RVs',
    termsOfService: 'Terms of service',
    insights: 'Insights'
  },

  urls: {
    matchMaker: 'https://rvezy.typeform.com/to/pVR43z'
  },

  helpUrls: {
    newRequest: 'https://help.rvezy.com/hc/en-us/requests/new',
    cancellationPolicy: 'https://help.rvezy.com/hc/en-us/articles/360037736674',
    ownerResponseRate: 'https://help.rvezy.com/hc/en-us/articles/4415854395291',
    verifyMyIdentity: 'https://help.rvezy.com/hc/en-us/articles/1260804182169',
    riskCancellationOwner:
      'https://help.rvezy.com/hc/en-us/articles/10509734333339-Keeping-the-RVezy-community-safe-for-hosts',
    riskCancellationRenter:
      'https://help.rvezy.com/hc/en-us/articles/10510239683611-Keeping-the-RVezy-community-safe-for-guests'
  },

  pages: {
    maintenance: {
      metaTitle: 'RVezy - Offline for maintenance, check back later'
    },

    owner: {
      editRV: {
        saveSuccessfully: 'Saved Successfully!',

        pages: {
          basics: 'Basics',
          details: 'Details',
          pricing: 'Pricing',
          photos: 'Photos',
          addOns: 'Add-ons',
          earnMore: 'Earn more',
          insurance: 'Insurance',
          availability: 'Availability',
          calendar: 'Calendar',
          preferences: 'Preferences'
        },

        pricing: {
          baseNightPrice: {
            title: 'Base Nightly Price',
            subtext:
              'This is the default nightly price it will cost renters to book your RV. You will have the ability to individually price popular days in your area above your base pricing.',
            error: 'The price must be between $20 and $5000'
          },

          securityDeposit: {
            title: 'Security Deposit',
            subtext:
              'The security deposit is taken from the Renter’s Credit Card 3 days before the start of the rental. RVezy holds onto this deposit until the RV is returned and the owner/renter closeout is complete. If there are any incidental damages or overage charges that need to be collected, we will use the Security deposit to pay out the owner. We recommend setting a security deposit of $1000.'
          },
          cleaningFee: {
            title: 'Cleaning Fee',
            subtext:
              'The cleaning fee is a charge that will be applied to every booking. The renter is expected to return the RV in the same condition that they receive it. This fee is to compensate the owner for the time spent preparing the RV for the renter.'
          },

          generator: {
            title: 'Generator',
            maxHourValueError: 'Value must be equal to or less than 23',
            maxCostValueError: 'Value must be equal to or less than 20',
            tooltip:
              '<b>Unlimited Generator Usage</b><br/>This allow renters to use your generator during their booking, without incurring any additional charges related to the generator.<br/><b>Hours included (Optional)</b><br/>This is the number of free hours per day, a renter can use your generator.</p> <b>Additional Hourly Charge (Optional)</b><br/>This is the additional dollar per hour rate that will be charged onced the included hours per day are surpassed.'
          }
        },

        details: {
          lengthError: 'Must be between 8ft - 50ft',
          weightError: 'Must be between 1lbs - 20000lbs',
          hitchWeightError: 'Must be at least 1lbs',
          seatbeltTwoPoint: '# of seat belts (lap belts)',
          seatbeltThreePoint: '# of seat belts (shoulder belts)'
        }
      }
    },

    index: {
      metaTitle: 'RV Rentals from $47/night | #1 RV Rental site in North America',
      metaDesc:
        'RVezy is the largest and safest RV rental marketplace in North America. Find RVs, motorhomes and travel trailers from coast to coast with $2M insurance, 24/7 roadside assistance, secure payments, and personal bilingual support.'
    },

    profile: {
      greeting: 'Hi',
      pageTitles: {
        profile: 'Profile',
        settings: 'Account Settings',
        payout: 'Payout Info',
        notifications: 'Notification Settings',
        goals: 'Host Goals',
        messageTemplates: 'Message Templates',
        drivers: 'Drivers',
        features: 'Features',
        payment: 'Payment Methods'
      },
      account: {
        phonePlaceholder: 'Enter a phone number'
      },

      driver: {
        addDriver: 'Add driver',
        licenseRequired: "Driver's license required",
        verification: {
          Approved: 'Approved',
          NotApproved: 'Not Approved',
          ValidationFailed: 'Validation failed',
          InProcessByRVezy: 'Validation in process by RVezy',
          VerificationInProgress: 'In progress'
        },
        addNewDriver: {
          title: 'Add New Driver'
        }
      },

      notification: {
        success: 'Saved Successfully!',
        error: 'Error occurred'
      }
    },

    rvDetails: {
      metaTitle: {
        default: 'Rent my {model} from {price}/night',
        rvCottage: 'Rent my RV Cottage from {price}/night'
      },
      metaDesc:
        'Top-rated {rvType} rental in {city}, {state}. Sleeps {sleeps} people. Weekly & monthly discounts. Best price guarantee! Check availability now.'
    },

    requestToBook: {
      metaTitle: 'Reserve this {year} {make} {model}',
      metaDesc:
        'Reserve this {year} {make} {model} for your next adventure! This {rentalType} is located in {city}, {state}.'
    }
  },

  /**
   * Skip: these are used in JS files.
   */
  doka: {
    labelReset: 'Reset',
    labelDefault: 'Default',
    labelAuto: 'Auto',
    labelNone: 'None',
    labelEdit: 'Edit',
    labelClose: 'Close',
    labelSizeExtraSmall: 'Extra small',
    labelSizeSmall: 'Small',
    labelSizeMediumSmall: 'Medium small',
    labelSizeMedium: 'Medium',
    labelSizeMediumLarge: 'Medium large',
    labelSizeLarge: 'Large',
    labelSizeExtraLarge: 'Extra large',
    labelButtonRevert: 'Revert',
    labelButtonCancel: 'Cancel',
    labelButtonUndo: 'Undo',
    labelButtonRedo: 'Redo',
    labelButtonExport: 'Done',
    statusLabelButtonClose: 'Close',
    cropLabel: 'Crop',
    cropLabelButtonRecenter: 'Recenter',
    cropLabelButtonRotateLeft: 'Rotate left',
    cropLabelButtonRotateRight: 'Rotate right',
    cropLabelButtonFlipHorizontal: 'Flip horizontal',
    cropLabelButtonFlipVertical: 'Flip vertical',
    cropLabelSelectPreset: 'Crop shape',
    cropLabelCropBoundary: 'Crop boundary',
    cropLabelCropBoundaryEdge: 'Edge of image',
    cropLabelCropBoundaryNone: 'None',
    cropLabelTabRotation: 'Rotation',
    cropLabelTabZoom: 'Zoom',
    filterLabel: 'Filter',
    filterLabelChrome: 'Chrome',
    filterLabelFade: 'Fade',
    filterLabelCold: 'Cold',
    filterLabelWarm: 'Warm',
    filterLabelPastel: 'Pastel',
    filterLabelMonoDefault: 'Mono',
    filterLabelMonoNoir: 'Noir',
    filterLabelMonoWash: 'Wash',
    filterLabelMonoStark: 'Stark',
    filterLabelSepiaDefault: 'Sepia',
    filterLabelSepiaBlues: 'Blues',
    filterLabelSepiaRust: 'Rust',
    filterLabelSepiaColor: 'Color',
    finetuneLabel: 'Finetune',
    finetuneLabelBrightness: 'Brightness',
    finetuneLabelContrast: 'Contrast',
    finetuneLabelSaturation: 'Saturation',
    finetuneLabelExposure: 'Exposure',
    finetuneLabelTemperature: 'Temperature',
    finetuneLabelGamma: 'Gamma',
    finetuneLabelClarity: 'Clarity',
    finetuneLabelVignette: 'Vignette',
    notSupportedOnThisBrowser: 'not supported on this browser',
    waitingForImage: 'Waiting for image',
    errorLoadingImage: 'Error loading image',
    creatingPreview: 'Creating preview…',
    loadingImage: 'Loading image…',
    errorUploadingImage: 'Error uploading image',
    uploadingImage: 'Uploading image…',
    errorProcessingImage: 'Error processing image',
    processingImage: 'Processing image…'
  },

  /**
   * Skip: these are used in JS files.
   *
   * The addon titles and descriptions key must stay the same for now because we
   * use strings stored in the addons backend to look up translations
   */
  DASHBOARD: {
    ADDONS: {
      SUBHEADING: 'Select any of the add-ons that apply to your listing.',
      DELIVERY_RENTER: 'Delivery / Pickup RV',
      DELIVERY_RENTER_DESC: 'Offer to deliver the RV to the renters campsite or other location',
      PER_NIGHT: 'Per Night',
      PER_TRIP: 'Per Trip',
      PRICE_PER_NIGHT: 'Price per Night',
      PRICE_PER_TRIP: 'Price per trip',
      DELIVERY: 'Delivery/Pickup up to {distance} {unit}',
      AIRPORT_PICKUP: 'Airport Pickup',
      AIRPORT_PICKUP_DESC: 'Pick up the renter at the airport and take them to the RV',
      BEDDING_LINENS: 'Bedding and Linens',
      BEDDING_LINENS_DESC: 'Supply sheets, towels, etc',
      PORTABLE_GPS: 'Portable GPS',
      BIKE_RACK: 'Bike Rack',
      PORTABLE_BBQ: 'Portable BBQ',
      PORTABLE_GENERATOR: 'Portable Generator',
      WIFI: 'Wifi',
      CUSTOM_PACKAGE: 'Custom Add-On',
      CUSTOM_PACKAGE_DESC: 'Add charge for additional equipment you will include with the rental',
      CONVENIENCE_PACKAGE: 'Convenience Package',
      CONVENIENCE_PACKAGE_DESC:
        'Let your imagination run wild! Create a custom package for your renters while they are renting your RV.',
      PROPANE_REFILL: 'Propane Refill Prepayment',
      PROPANE_REFILL_DESC: 'Allow your renters to return the RV without a full propane tank.',
      FUEL_REFILL_PREPAYMENT: 'Fuel Refill Prepayment',
      FUEL_REFILL_PREPAYMENT_DESC: 'Allow your renters to return the RV without a full fuel tank.',
      EMPTYING_SEPTIC_PREPAYMENT: 'Emptying Septic Prepayment',
      EMPTYING_SEPTIC_PREPAYMENT_DESC: 'Allow your renters to return the RV without emptying the septic tank.',
      EMTPYING_SEPTIC_PREPAYMENT: 'Emptying Septic Prepayment',
      EMTPYING_SEPTIC_PREPAYMENT_DESC: 'Allow your renters to return the RV without emptying the septic tank.',
      PICKUP_FROM: 'Pickup from',
      PRICE: 'Price',
      SELECT_AIRPORT: 'Select Airport',
      SUGGESTED_PRICE: 'Suggested price: {price}',
      SUGGESTED_DISCOUNT: 'Suggested Discount',
      DISTANCE_INCLUDES: 'This distance includes Delivery and Pickup of the RV at the end of rental.',
      DESCRIPTION: 'Description',
      CUSTOM_TOTAL: 'Custom Total'
    }
  },

  // Skip for now, as this errors out with vue-i18n's YAML parser
  'new-rv': {
    'suggested-price': 'Suggested Price:'
  }
}
