import { SendIdentifiersToClarity } from '~/lib/tracking/clarityTrack'

export default function ({ route, $experiment, $auth, $cookies }) {
  if (!process.server && $auth.loggedIn && ($experiment.isEnabled('rvz20755clarityFeatureFlag') ?? false)) {
    SendIdentifiersToClarity({
      customId: $auth.user?.Email ?? '',
      customPageId: route?.name ?? '',
      customSessionId: $cookies.get('rtsessionid') ?? ''
    })
  }
}
