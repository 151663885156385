/**
 * All the global actions that need to be forwarded to Partytown.
 */
export const forwardClarity = ['identify', 'custom-id', 'custom-session-id', 'custom-page-id', 'friendly-name']

/**
 * Injects Clarity Script into the head.
 */
export const injectClarity = ({ $partytown, $experiment, $config }) => {
  const isEnabled = $experiment.isEnabled('rvz20755clarityFeatureFlag') ?? false

  if (!isEnabled) {
    return
  }

  return {
    hid: 'microsoft-clarity',
    type: $partytown.clarityEnabled ? 'text/partytown' : 'text/javascript',
    innerHTML: injectClarityScript($config.id)
  }
}

/**
 * Generates and returns the actual script content for Microsoft Clarity to be
 * injected.
 */
const injectClarityScript = (projectId) => {
  return `
  (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${projectId}");
  `
}
