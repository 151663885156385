import isBot from '~/lib/isBot.js'
import { logDev } from '~/lib/dev-logging.js'
import { captureError } from '~/lib/logger'

export default function ({ $external, $config, $device }, inject) {
  inject('geocode', async (searchAddress) => {
    // Stop the bleed: Prevent Bots to geocoding SearchAddress query params on RV links on POI pages
    // isBot is more flexible than isCrawler
    if (!searchAddress || isBot($device.userAgent)) {
      return null
    }

    logDev({ domain: 'plugin', func: 'geocode', message: '$external.get', color: 'red' })

    const fetchFromGoogleMapsApi = async () => {
      const { results } = await $external.$get(
        `https://maps.googleapis.com/maps/api/geocode/json?key=${$config.googleMapsApiKey}&address=${encodeURIComponent(
          searchAddress
        )}&fields=formatted_address,name,geometry,types`
      )
      return results?.[0]
    }

    try {
      if ($config.isGisEnabled) {
        const { results } = await $external.$get(
          `https://gis.rvezy.com/Geocoding/Geocode/Raw?Key=${$config.googleMapsApiKey}&Address=${encodeURIComponent(
            searchAddress
          )}&fields=formatted_address,name,geometry,types`
        )
        return results[0]
      } else {
        return await fetchFromGoogleMapsApi()
      }
    } catch (err) {
      captureError(err)
      return await fetchFromGoogleMapsApi()
    }
  })
}
