import { SegmentEvents } from '~/lib/enums'
import {
  datadogAddAction,
  datadogAddError,
  datadogStartView,
  datadogSetUser,
  datadogRemoveUser,
  segmentTrack,
  segmentPage,
  segmentIdentify,
  segmentReset,
  googleRemarketingTrack
} from '~/lib/tracking/track'
import getOS from '~/lib/getOS'
import { BookingCommonEventFields } from './commons'
import { generateEventId } from '~/lib/useUtils'
import { logBrazeCustomEvent } from '~/lib/brazeTrack'
import { SendIdentifiersToClarity } from '~/lib/tracking/clarityTrack'

export const trackPageViewed = ({ $config, to, from }) => {
  datadogStartView({ to, from })

  if ($config.segment.enablePageViewTracking) {
    segmentPage({ to, from })
  }
}

export const trackUserAuthentication = ({ user, rtSessionId }) => {
  datadogSetUser({ user })
  segmentIdentify({ user })
  SendIdentifiersToClarity({ customId: user.Email, customSessionId: rtSessionId ?? '' })
}

export const trackUserDeauthentication = () => {
  datadogRemoveUser()
  segmentReset()
}

export const trackAffiliateLinkClicked = (data) => segmentTrack({ event: SegmentEvents.AffiliateLinkClicked, data })

export const trackAppBannerClicked = ({ device, ...params }) => {
  const data = {
    os: getOS(device),
    ...params
  }

  segmentTrack({ event: SegmentEvents.AppBannerClicked, data })
}

export const trackAppBannerClosed = ({ device, ...params }) => {
  const data = {
    os: getOS(device),
    ...params
  }

  segmentTrack({ event: SegmentEvents.AppBannerClosed, data })
}

export const trackBookingDetailsViewed = ({ booking, ...data }) => {
  const commonFields = new BookingCommonEventFields(booking)

  segmentTrack({
    event: SegmentEvents.BookingDetailsViewed,
    data: { ...commonFields.commons, ...data },
    eventVersion: 4
  })
}

export const trackBuyNowPayLaterContinueClicked = (data) =>
  segmentTrack({ event: SegmentEvents.BuyNowPayLaterContinueClicked, data })

export const trackBookingDetailsCtaClicked = (data) =>
  segmentTrack({ event: SegmentEvents.BookingDetailsCtaClicked, data, eventVersion: 2 })

export const trackBookingPaymentFormViewed = async (data) => {
  segmentTrack({ event: SegmentEvents.BookingPaymentFormViewed, data, eventVersion: 5 })
  await logBrazeCustomEvent(SegmentEvents.BookingPaymentFormViewed, data)
}

export const trackBookingPaymentInfoSubmitClicked = (data) =>
  segmentTrack({ event: SegmentEvents.BookingPaymentInfoSubmitClicked, data, eventVersion: 3 })

export const trackBookingPaymentInfoSubmitted = ({ data, contextData }) => {
  // To guarantee the uniqueness of the event ID, we append the current timestamp to a random generated UUID.
  const eventId = generateEventId()
  segmentTrack({
    event: SegmentEvents.BookingPaymentInfoSubmitted,
    eventVersion: 7,
    data,
    additionalContext: {
      fbp: contextData?.fbp,
      fbc: contextData?.fbc,
      environment: contextData?.environment,
      eventId
    }
  })

  segmentTrack({
    event: SegmentEvents.OrderCompleted,
    data: {
      checkout_id: data.bookingId.toString(),
      order_id: data.bookingId.toString(),
      affiliation: data.paymentProvider,
      total: Number(data.value?.toFixed(2) ?? 0),
      discount: Number(data.promoDollarValue?.toFixed(2) ?? 0),
      coupon: data.promoCode,
      currency: data.currency ?? 'CAD',
      products: [
        {
          product_id: data.rvId,
          sku: data.rvId,
          category: data.rvType,
          name: data.rvName,
          brand: data.rvMake,
          price: Number(data.smartNightlyRate?.toFixed(2) ?? data.nightlyRate?.toFixed(2) ?? 0),
          quantity: Number(data.tripLength ?? 0)
        }
      ]
    }
  })

  googleRemarketingTrack({
    event: 'purchase',
    data: {
      send_to: window.__NUXT__.config.googleAds.id,
      value: Number(data.value?.toFixed(2) ?? 0),
      items: [
        {
          id: data.rvId,
          start_date: data.startDate,
          end_date: data.endDate,
          google_business_vertical: 'hotel_rental'
        }
      ]
    }
  })
}

export const trackBookingPaymentInfoSubmitFailed = (data) =>
  segmentTrack({ event: SegmentEvents.BookingPaymentInfoSubmitFailed, data })

export const trackCalendarImportFieldClicked = (data) =>
  segmentTrack({ event: SegmentEvents.CalendarImportFieldClicked, data })

export const trackCalendarImportModalViewed = (data) =>
  segmentTrack({ event: SegmentEvents.CalendarImportModalViewed, data })

export const trackCalendarImportSubmitted = (data) =>
  segmentTrack({ event: SegmentEvents.CalendarImportSubmitted, data })

export const trackCalendarConnectModalViewed = (data) =>
  segmentTrack({ event: SegmentEvents.CalendarConnectModalViewed, data })

export const trackCalendarConnectCtaClicked = (data) =>
  segmentTrack({ event: SegmentEvents.CalendarConnectCtaClicked, data })

export const trackCalendarConnectLinkCopied = (data) =>
  segmentTrack({ event: SegmentEvents.CalendarConnectLinkCopied, data })

export const trackCalendarUpdateSuccessful = (data) =>
  segmentTrack({ event: SegmentEvents.CalendarUpdateSuccessful, data, eventVersion: 3 })

export const trackDeliveryEducationalModalClicked = (data) =>
  segmentTrack({ event: SegmentEvents.DeliveryEducationalModalClicked, data })

export const trackDisputeChargesClicked = (data) => segmentTrack({ event: SegmentEvents.DisputeChargesClicked, data })

export const trackEditListingSaved = (data) => segmentTrack({ event: SegmentEvents.EditListingSaved, data })

export const trackEditListingViewed = (data) => {
  segmentTrack({ event: SegmentEvents.EditListingViewed, data, eventVersion: 2 })
}

export const trackExperimentViewed = (data) => segmentTrack({ event: SegmentEvents.ExperimentViewed, data })

export const trackExternalLinkClicked = (data) =>
  segmentTrack({ event: SegmentEvents.ExternalLinkClicked, data, eventVersion: 2 })

export const trackGuestRequestMoneySent = (data) => segmentTrack({ event: SegmentEvents.GuestRequestMoneySent, data })

export const trackHelpCenterClicked = (data) => segmentTrack({ event: SegmentEvents.HelpCenterClicked, data })

export const trackListingStepSubmitted = (data) => {
  const eventId = generateEventId()
  segmentTrack({ event: SegmentEvents.ListingStepSubmitted, data, eventVersion: 2, additionalContext: { eventId } })
}

export const trackMessageTemplateSelected = (data) =>
  segmentTrack({ event: SegmentEvents.MessageTemplateSelected, data })

export const trackCurrencySelectorClicked = (data) =>
  segmentTrack({ event: SegmentEvents.CurrencySelectorClicked, data })

export const trackCurrencyUpdated = (data) => segmentTrack({ event: SegmentEvents.CurrencyUpdated, data })

export const trackOwnerCalendarViewed = (data) => segmentTrack({ event: SegmentEvents.OwnerCalendarViewed, data })

export const trackOwnerCancellationModalClosed = (data) =>
  segmentTrack({ event: SegmentEvents.OwnerCancellationModalClosed, data })

export const trackOwnerCancellationModalViewed = (data) =>
  segmentTrack({ event: SegmentEvents.OwnerCancellationModalViewed, data })

export const trackPayoutGuaranteeModalViewed = ({ booking, ...data }) => {
  const commonFields = new BookingCommonEventFields(booking)

  segmentTrack({ event: SegmentEvents.PayoutGuaranteeModalViewed, data: { ...commonFields.commons, ...data } })
}

export const trackPaymentUpdateSubmitted = (data) => segmentTrack({ event: SegmentEvents.PaymentUpdateSubmitted, data })

export const trackPaymentUpdateSuccessful = (data) =>
  segmentTrack({ event: SegmentEvents.PaymentUpdateSuccessful, data })

export const trackPaymentUpdateViewed = (data) => segmentTrack({ event: SegmentEvents.PaymentUpdateViewed, data })

export const trackPhotoCarouselUpdateSuccessful = (data) =>
  segmentTrack({ event: SegmentEvents.PhotoCarouselUpdateSuccessful, data, eventVersion: 2 })

export const trackPhotoLightBoxUpdateSuccessful = (data) =>
  segmentTrack({ event: SegmentEvents.PhotoLightBoxUpdateSuccessful, data, eventVersion: 2 })

export const trackRefundMethodSelected = (data) => segmentTrack({ event: SegmentEvents.RefundMethodSelected, data })

export const trackRequestMoneyClicked = (data) => segmentTrack({ event: SegmentEvents.RequestMoneyClicked, data })

export const trackRequestMoneyReviewed = (data) => segmentTrack({ event: SegmentEvents.RequestMoneyReviewed, data })

export const trackSearchForRvClicked = (data) => segmentTrack({ event: SegmentEvents.SearchForRvClicked, data })

export const trackSellMyRvClicked = (data) => segmentTrack({ event: SegmentEvents.SellMyRvClicked, data })

export const trackSendMoneyBookingClicked = (data) =>
  segmentTrack({ event: SegmentEvents.SendMoneyBookingClicked, data })

export const trackSendMoneyExited = (data) => segmentTrack({ event: SegmentEvents.SendMoneyExited, data })

export const trackShareButtonClicked = (data) => segmentTrack({ event: SegmentEvents.ShareButtonClicked, data })

export const trackSignupExited = (data) => segmentTrack({ event: SegmentEvents.SignupExited, data, eventVersion: 2 })

export const trackSignupFailed = (data) => segmentTrack({ event: SegmentEvents.SignupFailed, data })

export const trackSignupViewed = (data) => segmentTrack({ event: SegmentEvents.SignupViewed, data })

export const trackSortMenuClosed = (data) => segmentTrack({ event: SegmentEvents.SortMenuClosed, data })

export const trackSortMenuViewed = (data) => segmentTrack({ event: SegmentEvents.SortMenuViewed, data })

export const trackSortOptionClicked = (data) => segmentTrack({ event: SegmentEvents.SortOptionClicked, data })

export const trackSpecialOfferFormSubmittedSuccessfully = (data, booking) => {
  const commonFields = new BookingCommonEventFields(booking)
  segmentTrack({
    event: SegmentEvents.SpecialOfferFormSubmittedSuccessfully,
    data: { ...commonFields.commons, ...data }
  })
}

export const trackTripQuotePresented = ({ eventData }) =>
  segmentTrack({ event: SegmentEvents.TripQuotePresented, data: eventData, eventVersion: 6 })

export const trackTripQuoteViewed = ({ eventData }) =>
  segmentTrack({ event: SegmentEvents.TripQuoteViewed, data: eventData, eventVersion: 4 })

export const trackError = (error, context) => {
  datadogAddError(error, context)
}

export const trackAction = (name, context) => {
  datadogAddAction(name, context)
}

export const trackSignup = (data) => {
  segmentTrack({
    event: SegmentEvents.Signup,
    data: {
      username: data.userName,
      type: data.provider,
      phone: data.phone,
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      context: {
        groupId: data.userType
      }
    }
  })
}

// eslint-disable-next-line no-unused-vars
export const trackRequestBookingStarted = (data) => {}

// https://rvezy.atlassian.net/wiki/spaces/DATA/pages/2430697473/TipsAndResourcesCardClicked
export const trackTipsAndResourcesCardClicked = (data) =>
  segmentTrack({ event: SegmentEvents.TipsAndResourcesCardClicked, data })

// https://rvezy.atlassian.net/wiki/spaces/DATA/pages/2451406849/SuperhostLearnMoreClicked
export const trackSuperhostLearnMoreClicked = (data) =>
  segmentTrack({ event: SegmentEvents.SuperhostLearnMoreClicked, data, eventVersion: 2 })

// https://rvezy.atlassian.net/wiki/spaces/DATA/pages/2522480660/ReviewRequestViewed
export const trackReviewRequestViewed = (data) =>
  segmentTrack({ event: SegmentEvents.ReviewRequestViewed, data, eventVersion: 3 })

// https://rvezy.atlassian.net/wiki/spaces/DATA/pages/2522480642/ReviewRequestSubmitted
export const trackReviewRequestSubmitted = (data) =>
  segmentTrack({ event: SegmentEvents.ReviewRequestSubmitted, data, eventVersion: 3 })

// https://rvezy.atlassian.net/wiki/spaces/DATA/pages/2711486465/BannerClicked
export const trackBannerClicked = (data) => segmentTrack({ event: SegmentEvents.BannerClicked, data })
