export function SendEventToClarity(event) {
  try {
    window?.clarity?.('event', event)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error firing clarity event', e)
  }
}

export function SendTagToClarity(tagName, tagValue) {
  try {
    window?.clarity?.('set', tagName, tagValue)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error on clarity Tag', e)
  }
}

export function SendConsentToClarity() {
  try {
    window?.clarity?.('consent')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error on clarity Consent', e)
  }
}

export function SendIdentifiersToClarity({ customId, customSessionId, customPageId, friendlyName }) {
  try {
    window?.clarity?.('identify', customId, customSessionId, customPageId, friendlyName)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error on clarity Identify', e)
  }
}
