import { LanguageToi18nLocale, i18nLocaleEnum, DefaultExperimentValues } from '../enums'
import { getUserEmail, getUserId } from '../auth'
import { trackExperimentViewed } from '~/lib/tracking'
import { isWebview } from '~/lib/useUtils'

export function getConfigCatUserObject({ $auth, $getSessionID, $device, $config, $geolocation, $cookies }) {
  const user = $auth?.user || {}
  const sessionId = $getSessionID()
  const userAgent = $device.userAgent || 'unknown'
  const device = getUserDevice($device, $cookies)
  const environment = $config.hostEnvironment
  const country = $geolocation.countryCode || null

  const email = getUserEmail($auth)
  const UserId = getUserId($auth)
  const Language = LanguageToi18nLocale(user?.Language) || i18nLocaleEnum.en
  const IsOwner = user.IsOwner ? 'yes' : 'no'
  const Region = $geolocation.regionCode || null

  return {
    email,
    country,
    custom: {
      UserId,
      SessionId: sessionId,
      UserAgent: userAgent,
      UserDevice: device,
      Language,
      IsOwner,
      EnvironmentName: environment,
      Region
    }
  }
}

export function getUserDevice($device, $cookies) {
  if (isWebview($cookies.get('platform'))) {
    return 'webview'
  }
  if ($device.isDesktop) {
    return 'desktop'
  }
  if ($device.isTablet) {
    return 'tablet'
  }
  if ($device.isMobile) {
    return 'mobile'
  }
  return 'unknown'
}

export function trackExperiment(experiment, { startup = false, isWebview }) {
  if (
    experiment?.tracked &&
    (!isWebview || experiment?.trackWebview) &&
    (experiment.variant !== experiment.defaultVariant || experiment.trackDefaultVariant) &&
    experiment.trackOnStartup === startup
  ) {
    trackExperimentViewed({
      experimentName: experiment.key,
      variantName: `${experiment.variant}`
    })
  }
}

export function trackStartup(experiments, isWebview) {
  Object.values(experiments).forEach((experiment) => {
    trackExperiment(experiment, { startup: true, isWebview })
  })
}

export function isExperimentValue(value, variant) {
  return value === variant
}

export function isVariant(value) {
  return isExperimentValue(value, DefaultExperimentValues.Variant)
}

export function isControl(value) {
  return (
    isExperimentValue(value, DefaultExperimentValues.Off) || isExperimentValue(value, DefaultExperimentValues.Control)
  )
}

export const useConfigCat = (experiments) => {
  function get(experimentKey) {
    return experiments.evaluated[experimentKey]
  }

  function getVariant(experimentKey) {
    return get(experimentKey)?.variant
  }

  function getAll() {
    return experiments.evaluated
  }

  function getAllVariants() {
    const variants = {}
    for (const key in experiments.evaluated) {
      variants[key] = experiments.evaluated[key].variant
    }
    return variants
  }

  function getOverrides() {
    return experiments.overriden
  }

  function getOverride(experimentKey, defaultValue) {
    return get(experimentKey)?.overrider ?? defaultValue
  }

  function getConfig(experimentKey) {
    return get(experimentKey)?.config || {}
  }

  function isEnabled(experimentKey) {
    if (!experimentKey) return false

    const experiment = get(experimentKey)
    if (!experiment) return false

    return experiment.variant !== experiment.defaultVariant
  }

  return {
    get,
    getVariant,
    getAll,
    getAllVariants,
    getOverrides,
    getOverride,
    getConfig,
    isEnabled
  }
}
