
import { isFirstStep } from '~/lib/rvs/index.js'
import showInsurance from '~/lib/showInsurance.js'

export default {
  props: {
    disableNextButton: {
      type: Boolean,
      default: true
    },

    isLastStep: {
      type: Boolean,
      default: false
    },

    isPhoneNumberStep: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    canShowNeedHelp() {
      return !this.isLastStep && !this.isPhoneNumberStep
    },

    hasBackButton() {
      if (this.isPhoneNumberStep) return

      const rentalType = this.$store.state.rv.rv.RentalType
      const rvType = this.$store.state.rv.rv.RVType
      const hasInsurance = showInsurance(this.$store.state.rv.rv)

      const step = this.$route.params.field
      return !isFirstStep({ rentalType, rvType, hasInsurance }, step)
    },

    nextButtonText() {
      if (this.isLastStep) {
        if (this.$auth.user.VerificationStatus === 'Verified') {
          return this.$t('doneListing')
        } else {
          return this.$t('doneListingWithoutVouched')
        }
      } else {
        return this.$t('actions.next')
      }
    }
  },

  methods: {
    previousStep() {
      this.$nuxt.$emit('new-rv-previous-step')
    },

    nextStep() {
      this.$nuxt.$emit('new-rv-next-step')
    },

    phoneNumberNextStep() {
      this.$emit('saveStep')
    }
  }
}
