import { DELIVERY_ADDON_PREFIX } from '@/constants'
import { AddOnType } from '@/lib/enums/frontendEnums'

/**
 * @deprecated Replaced with per km pricing, use only for legacy addons
 */
export const isDeliveryAddon = (addon) => {
  return addon.Slug !== '' && String(addon.Slug).startsWith(DELIVERY_ADDON_PREFIX)
}

export const getAddons = (rvAddons, storeAddons) => {
  if (!rvAddons) return []

  return rvAddons
    .filter((x) => !x?.Slug?.startsWith(DELIVERY_ADDON_PREFIX))
    .map((addon) => {
      return {
        ...addon,
        isSelected: storeAddons.some((storeAddon) => storeAddon.Slug === addon.Slug)
      }
    })
}

export const getChangeRequestAddons = (rvAddons, bookingAddons, selectedAddons) => {
  if (!rvAddons && !bookingAddons) return []

  const allAddons = [
    ...rvAddons,
    ...bookingAddons
      .filter(
        (bookingAddon) =>
          !rvAddons.some((rvAddon) => rvAddon.Id === bookingAddon.IdInRV) &&
          bookingAddon.AddOnType !== 'CleaningFee' &&
          bookingAddon.AddOnType !== 'Roadside'
      )
      .map((addon) => {
        return {
          ...addon,
          Status: 'Active'
        }
      })
  ]
  return allAddons
    .filter((x) => !x?.Slug?.startsWith(DELIVERY_ADDON_PREFIX))
    .map((addon) => {
      return {
        ...addon,
        isSelected: selectedAddons.some((storeAddon) => storeAddon.Slug === addon.Slug)
      }
    })
}

export const getAddonsPicked = (addons) => {
  if (!addons) return []

  return addons
    .filter((addon) => addon?.isSelected)
    .map((addon) => {
      return {
        ...addon,
        IdInRV: addon.Id
      }
    })
}

export const changeRequestItems = (addons) => {
  if (!addons) return []
  return addons.filter((x) => x.AddOnType !== AddOnType.CleaningFee && x.AddOnType !== AddOnType.Roadside)
}
