
import { isWebview } from '~/lib/useUtils'
import postMessageToMobileApp from '@/lib/postMessageToMobileApp'

export default {
  props: {
    isPhoneNumberStep: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isWebview() {
      return isWebview(this.$cookies.get('platform'))
    },

    breadcrumbItems() {
      if (this.isPhoneNumberStep) {
        return [
          {
            text: this.$t('pageLink.listAnRV')
          },
          {
            text: this.$t('AccountInformation')
          }
        ]
      }

      return [
        {
          text: this.$t('pageLink.listAnRV')
        },
        {
          text: this.$route.params.field ? this.$t(`breadcrumb.${this.$route.params.field}`) : ''
        }
      ]
    },

    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    }
  },

  methods: {
    saveAndExit() {
      if (!this.isPhoneNumberStep) {
        this.$nuxt.$emit('new-rv-save-and-exit')
        return
      }

      if (!this.isWebview) {
        this.goBack()
        return
      }

      postMessageToMobileApp({
        android: { func: 'closeWebPage' },
        ios: { command: 'closeWebPage' }
      })
    },

    previousStep() {
      if (this.isPhoneNumberStep) {
        this.goBack()
        return
      }

      this.$nuxt.$emit('new-rv-previous-step')
    },

    goTo(code) {
      this.$router.replace(
        this.localePath(
          {
            name: this.$route.name.substr(0, -5),
            params: this.$route.$params,
            query: { locale: code, ...this.$route.query }
          },
          code
        )
      )
    },

    goBack() {
      this.$router.go(-1)
    }
  }
}
