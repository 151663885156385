import dayjs from '~/lib/useDates'

import {
  experienceDriverPreference,
  festivalsEventsPreference,
  festivalsEventsPreferenceDestination,
  hasExperiencedDriver,
  isGoingToFestivalOrEvent,
  stationaryAfterDelivery,
  bookingHasDelivery,
  getDistancePriceKms
} from '~/lib/bookings'
import { BookingStageEnum, CountryCodeToCurrencyCode } from '~/lib/enums'
import getFreeDistanceKms from '@/lib/getFreeDistanceKms'

export class BookingCommonEventFields {
  commonFields = {}

  constructor(booking) {
    const rvSnapshot = booking.RVSnapshot
    const financial = booking.Financial

    const hasDelivery = bookingHasDelivery(booking)

    const hasTowingExperience = experienceDriverPreference(booking) ? hasExperiencedDriver(booking) : undefined
    const isAttendingFestival = festivalsEventsPreference(booking) ? isGoingToFestivalOrEvent(booking) : undefined

    this.commonFields = {
      bookingId: booking.Id,
      bookingState: BookingStageEnum[booking.Stage],
      bookingType: booking.Type.toLowerCase(),
      rvId: booking.RVId,

      startDate: dayjs.utc(booking.DateStart).format('YYYY-MM-DD'),
      endDate: dayjs.utc(booking.DateEnd).format('YYYY-MM-DD'),
      adults: booking.Adults,
      children: booking.Children,

      currency: CountryCodeToCurrencyCode(booking.Country),
      roadsidePrice: financial.TotalRoadsides,
      insurancePrice: financial.TotalInsurances,
      cleaningPrice: financial.TotalCleanings,
      securityDepositPrice: financial.TotalSecurityDeposits,
      generatorPriceHourly: rvSnapshot.IsChargeGenerator ? rvSnapshot.ChargePerHourOver ?? null : null,
      distancePriceKms: getDistancePriceKms(booking),
      freeDistanceKms: getFreeDistanceKms(rvSnapshot, financial),
      freeGeneratorHours: rvSnapshot.IsChargeGenerator ? rvSnapshot.FreeHoursPerDay ?? null : null,
      rentalTotal: financial.RenterDiscountedTotal,
      pricePaid: financial.TotalRentalPaid,
      totalPayout: financial.TotalPayoutAmounts,
      hasPaymentInfo: !booking.NoPaymentMethod,

      isEzymatch: booking.IsEZYmatch,
      hasPets: booking.Pets > 0,
      hasDelivery,
      deliveryDistance: hasDelivery && booking.DeliveryDistance ? booking.DeliveryDistance : undefined,
      deliveryPrice: hasDelivery ? financial.TotalDelivery : undefined,

      isStationaryDelivery: booking.RvUsage ? stationaryAfterDelivery(booking) : undefined,
      tripLength: booking.NumberOfNight,

      listingType: rvSnapshot.RentalType,
      rvType: rvSnapshot.RVType,
      listingCountry: rvSnapshot.Country.toLowerCase(),
      listingRegion: rvSnapshot.RVState,
      listingCity: rvSnapshot.City,

      hasTowingExperience,
      isAttendingFestival,
      festivalName: isAttendingFestival ? festivalsEventsPreferenceDestination(booking) : undefined,

      tripLeadTime: dayjs(booking.StartDate).diff(dayjs(), 'days')
    }
  }

  get commons() {
    return this.commonFields
  }
}
