import { Cookies } from '~/lib/enums'

export const state = () => ({
  currencyCode: null
})

export const getters = {
  selectedCurrencyCode: (state) => state.currencyCode
}

export const mutations = {
  SET_CURRENCY(state, currency) {
    state.currencyCode = currency
  }
}

export const actions = {
  SET_CURRENCY({ commit }, currency) {
    commit('SET_CURRENCY', currency)
    this.$cookies.set(Cookies.Currency, currency, {
      domain: this.$config.cookieDomain,
      path: '/'
    })
  }
}
