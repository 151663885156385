
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },

    target: {
      type: String,
      default: null
    },

    rel: {
      type: String,
      default: null
    }
  },

  methods: {
    getComponentIs(item) {
      if (item?.to) {
        return 'nuxt-link'
      } else if (item?.href) {
        return 'a'
      } else {
        return 'span'
      }
    }
  }
}
