
export default {
  name: 'ButtonNeedHelp',
  methods: {
    needHelp() {
      this.$store.dispatch('modals/QUEUE_MODAL', {
        name: 'BookCallModdal'
      })
    }
  }
}
