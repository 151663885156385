/* eslint-disable no-console */

import pino from 'pino'

const initParams = {
  browser: {
    asObject: true
  }
}

export const logger = pino(initParams)

export const captureError = (error, context, config) => {
  const sentToDD = config?.sentToDD

  if (sentToDD) {
    logger.info({ err: error }, '[Error sent to Datadog]')

    if (context) {
      logger.info(context, '[Error context]')
    }

    return
  }

  if (error instanceof Error) {
    logger.error({ err: error })
  }

  const err = new Error(error, context)
  logger.error({ err })
}
