import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

import { isWebview } from '~/lib/utils'
import { LanguageToi18nLocale, SegmentUserSignupSources } from '~/lib/enums'
import postMessageToMobileApp from '@/lib/postMessageToMobileApp'
import mobileCapabilities, { FEATURES } from '@/lib/mobileCapabilities'
import { logDev } from '~/lib/dev-logging.js'
import { SendEventToClarity } from '~/lib/tracking/clarityTrack'

/**
 * Helper function to log out events to the console for debugging purposes.
 */
function log(func, message) {
  if (window?.__NUXT__?.config?.trackingLoggingEnabled) {
    // eslint-disable-next-line no-console
    logDev({ domain: 'tracking', func, message, color: 'green' })
  }
}

/**
 * DataDog
 */
export const datadogAddError = (err, context) => {
  window?.DD_RUM?.onReady(() => {
    const error = err instanceof Error ? err : new Error(err)
    window?.DD_RUM?.addError(error, context ?? { cause: 'Unknown' })
  })
}

export const datadogAddAction = (name, context) => {
  if (!name) {
    return datadogAddError('datadogAddAction: name is required', context)
  }

  window?.DD_RUM?.onReady(() => {
    window?.DD_RUM?.addAction(name, context)
  })
}

export const datadogStartView = ({ to, from }) => {
  /**
   * Manually call Datadog's `startView` because this doesn't seem to be
   * auto-detected very well when running Datadog through Partytown.
   *
   * We replace the path's dynamic pieces (e.g. :id), with '?'.
   */
  if (to.path === from.path && from.name !== null) return

  let path = to.path || '/'

  if (to.matched.length) {
    const matchedPath = to.matched[to.matched.length - 1].path || '/'
    path = matchedPath.replace(/(:\S+?)\b/gm, '?')
  }

  window?.DD_RUM?.onReady(() => {
    window?.DD_RUM?.startView({
      name: path
    })
  })
}

export const datadogSetUser = ({ user }) => {
  window?.DD_RUM?.onReady(() => {
    window?.DD_RUM?.setUser({
      id: user.Id,
      name: `${user.FirstName} ${user.LastName}`,
      email: user.Email
    })
  })
}

export const datadogRemoveUser = () => {
  window?.DD_RUM?.onReady(() => {
    window?.DD_RUM?.clearUser()
  })
}

/**
 *  Segment
 */
export const segmentTrack = ({ event, data, eventVersion, additionalContext }) => {
  log('segmentTrack', { event, data, eventVersion, additionalContext })
  if (isWebview(Cookies.get('platform'))) {
    const enabledEventVersioning = mobileCapabilities(Cookies.get('capabilities'), [FEATURES.CAN_TRACK_SEGMENT_VERSION])
    const androidData = { func: 'trackSegmentEvent', params: [event, JSON.stringify(data)] }
    const iosData = {
      command: 'trackSegmentEvent',
      name: event,
      parameters: data
    }

    if (enabledEventVersioning && eventVersion) {
      androidData.params.push(eventVersion)
      iosData.eventVersion = eventVersion
    }

    postMessageToMobileApp({
      android: androidData,
      ios: iosData
    })
    return
  }

  const additionalData = {
    context: {
      protocols: {}
    }
  }

  if (eventVersion) {
    additionalData.context.protocols = {
      event_version: eventVersion
    }
  }

  if (additionalContext) {
    additionalData.context.protocols = {
      ...additionalData.context.protocols,
      ...additionalContext
    }
  }

  window?.analytics?.track(event, data, additionalData)
  // Fire clarity together with segment events
  SendEventToClarity(event)
}

export const segmentPage = ({ to, from }) => {
  window?.analytics?.page(to.name, {
    path: to.path,
    referrer: from.name !== null ? from.path : document.referrer,
    search: JSON.stringify(to.query) !== '{}' ? JSON.stringify(to.query) : '',
    title: document.title,
    url: document.location.href
  })
}

export const getSegmentAnonymousId = () => {
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/
  // Return cookie or localStorage
  let anonymousId =
    Cookies.get('ajs_anonymous_id') ?? JSON.parse(window?.localStorage?.getItem('ajs_anonymous_id') || null)

  if (!anonymousId) {
    // Create a new UUID if no anonymousId are retrived
    anonymousId = uuidv4()
    window?.analytics?.setAnonymousId(anonymousId)
  }

  return anonymousId
}

export const segmentIdentify = ({ user }) => {
  let phone = ''
  if (user.PhoneNumber !== null && user.PhoneNumber !== '') {
    phone = user.PhoneNumber
    if (!user.PhoneNumber.includes('+') && user.CountryPhoneCode) {
      phone = `${user.CountryPhoneCode}${user.PhoneNumber}`
    }
  }

  window?.analytics?.identify(user.Id, {
    email: user.Email,
    firstName: user.FirstName,
    lastName: user.LastName,
    phone,
    language: LanguageToi18nLocale(user.Language),
    createdAt: user.CreatedDate,
    lastLogin: user.LastLogin,
    isOwner: user.IsOwner,
    hasRVListed: user.HasRv,
    signupSource: SegmentUserSignupSources[user.SignupFrom] ?? '',
    isSuperhost: user?.SuperHost?.IsActive
  })
}

export const segmentReset = () => {
  window?.analytics?.reset()
}

/**
 * Google Remarketing
 */
export const googleRemarketingTrack = ({ event, data }) => {
  log('googleRemarketingTrack', { event, data })
  window?.gtag('event', event, data)
}
