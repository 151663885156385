import { Cookies, CountryEnumKey, CurrencyEnumKey } from '~/lib/enums'

export const actions = {
  async nuxtServerInit({ dispatch, commit }, { $captureError, $config, $redis, $axios, $cookies, $geolocation }) {
    if ($cookies.get(Cookies.Currency)) {
      dispatch('currency/SET_CURRENCY', $cookies.get(Cookies.Currency))
    } else {
      commit(
        'currency/SET_CURRENCY',
        $geolocation?.countryCode === CountryEnumKey.CA ? CurrencyEnumKey.CAD : CurrencyEnumKey.USD
      )
    }

    if ($config.canGetReviewsFile)
      await dispatch('verifiedReview/SET_AGGREGATE_REVIEW', { $axios, $captureError, $redis })
  }
}
