import Enum from './enum.js'

/**
 * @enum {string}
 */
export class i18nLocaleEnum extends Enum {
  static en = 'en'
  static fr = 'fr'
}

/**
 * @enum {string}
 */
export class BookingPseudoStage extends Enum {
  static AddDriver = 'AddDriver'
  static PrepaidAOA = 'PrepaidAOA'
  static SignRentalAgreement = 'SignRentalAgreement'
  static TomorrowTrip = 'TomorrowTrip'
  static TodayTrip = 'TodayTrip'
  static OnTrip = 'OnTrip'
  static PendingChangeRequest = 'PendingChangeRequest'
  static NotSupported = 'NotSupported'
  static ReadyToReview = 'ReadyToReview'
}

/**
 * @enum {string}
 * @description This is used in different signup flows.
 * The "List an RV" field is hidden for implicit Owner and Renter.
 * Also used to, when necessary, skip the first step of the signin modal.
 * For the owner it hides the List an RV field but shows the "How you've heard of us" instead
 */
export class SignupUserType extends Enum {
  static Owner = 'owner'
  static Renter = 'renter'
  static Unknown = 'unknown'
}

/**
 * @enum {string}
 */
export class PromocodeErrors extends Enum {
  static PROMOTION_BOOKING_REQUIREMENT = 'PROMOTION_BOOKING_REQUIREMENT'
  static PROMOTIONCODE_NOT_EXIST = 'PROMOTIONCODE_NOT_EXIST'
  static PROMOTION_NOT_ACTIVE = 'PROMOTION_NOT_ACTIVE'
  static PROMOTION_LIMIT_USER = 'PROMOTION_LIMIT_USER'
  static PROMOTION_LIMIT_TOTAL_QUANTITY = 'PROMOTION_LIMIT_TOTAL_QUANTITY'
  static PROMOTION_WRONG_COUNTRY = 'PROMOTION_WRONG_COUNTRY'
}

/**
 * @enum {string}
 */
export class QueryStrings extends Enum {
  static swLat = 'SwLat'
  static swLng = 'SwLng'
  static neLat = 'NeLat'
  static neLng = 'NeLng'
  static searchAddress = 'SearchAddress'

  static startDate = 'StartDate'
  static endDate = 'EndDate'

  static adults = 'Adults'
  static children = 'Children'
  static petFriendly = 'PetFriendly'
  static types = 'Types'
  static delivery = 'Delivery'
  static instantbook = 'InstantBook'
  static minPrice = 'MinPrice'
  static maxPrice = 'MaxPrice'
  static amenities = 'Amenities'
  static minLength = 'MinLength'
  static maxLength = 'MaxLength'
  static minWeight = 'MinWeight'
  static maxWeight = 'MaxWeight'
  static sortOrder = 'SortOrder'
  static currentPage = 'currentPage'
  static pageSize = 'pageSize'
  static SearchLat = 'SearchLat'
  static SearchLng = 'SearchLng'
  static minYear = 'MinYear'
  static maxYear = 'MaxYear'
  static rvBrand = 'RvBrand'
  static experienceNotRequired = 'ExperienceNotRequired'
  static festivalFriendly = 'FestivalFriendly'
  static superhost = 'IsSuperHost'

  static error = 'Error'
}

/**
 * @enum {string}
 */
export class RouteBaseNameEnum extends Enum {
  static INDEX = 'index'
  static RV_SEARCH = 'rv-search'
  static RV_RENTAL = 'rv-rental'
}

/**
 * @enum {string}
 */
export class ImageFormat extends Enum {
  static WEBP = 'webp'
  static PNG = 'png'
  static JPG = 'jpg'
}

/**
 * @enum {string}
 */
export class ImageResolution extends Enum {
  static SMALL = 'small'
  static MEDIUM = 'medium'
  static LARGE = 'large'
}

/**
 * @enum {string}
 */
export class ImageQuality extends Enum {
  static LOW = 'low'
  static MEDIUM = 'medium'
  static HIGH = 'high'
  static DEFAULT = 'high'
  static NONE = 'none'
}

/**
 * @enum {string}
 */
export class ImageType extends Enum {
  static AVATAR = 'avatar'
  static RV = 'rv'
  static POI = 'poi'
  static REVIEWS = 'reviews'
}

export class BookingPaymentStates extends Enum {
  static intialDepositTaken = 0
  static awaitingDepositTaken = 1
  static fullPaymentTaken = 2
  static fullPaymentFailed = 3
  static awaitingFullPayment = 4
  static securityDepositTaken = 5
  static securityDepositFailed = 6
  static awaitingSecurityDeposit = 7
}

export class Manufacturer extends Enum {
  static OTHER = 'Other'
}

export class DateRangeType extends Enum {
  static START = 'START'
  static END = 'END'
}

export class MonthsAsDays extends Enum {
  static MONTHS_1 = 30
  static MONTHS_3 = 91
  static MONTHS_6 = 183
  static MONTHS_9 = 274
  static MONTHS_12 = 365
  static MONTHS_15 = 456
  static MONTHS_18 = 548
  static MONTHS_21 = 639
  static MONTHS_24 = 730
}

export class CalendarDayType extends Enum {
  static UNAVAILABLE = 'UNAVAILABLE'
}

export class DashboardTrips extends Enum {
  static CompletedTrip = 'completed_trip'
  static BookingTrips = 'booking_trips'
  static BookingTripsPending = 'booking_trips_pending'
}

export class DeclinationActions extends Enum {
  static UPDATE_CALENDAR = 'UPDATE_CALENDAR'
  static SYNC_CALENDAR = 'SYNC_CALENDAR'
  static CALENDAR_SETTINGS = 'CALENDAR_SETTINGS'
  static LEARN_HOST_PROTECTION = 'LEARN_HOST_PROTECTION'
  static EVENT_PREFERENCES = 'EVENT_PREFERENCES'
  static TOWING_PREFERENCES = 'TOWING_PREFERENCES'
  static DRIVING_PREFERENCES = 'DRIVING_PREFERENCES'
  static UNPUBLISH = 'UNPUBLISH'
}

export class FlagType extends Enum {
  static Experiment = 'experiment'
  static Feature = 'feature'
  static Configuration = 'configuration'
}

export class BadgeColors extends Enum {
  static Unknown = 'unknown'
  static Blue = 'blue'
  static Yellow = 'yellow'
  static Green = 'green'
  static Red = 'red'
  static Grey = 'grey'
}

export class FuelTypes extends Enum {
  static Diesel = 0
  static Gasoline = 1
}

export class SearchTypes extends Enum {
  static SearchAll = 'searchAll'
  static Drivable = 'drivable'
  static Towable = 'towable'
  static Delivery = 'delivery'
}

export class CancellationPolicyOptions extends Enum {
  static NoDatesSelected = 'NoDatesSelected'
  static LessThan7Days = 'LessThan7Days'
  static Between8To30Days = 'Between8To30Days'
  static MoreThan30Days = 'MoreThan30Days'
  static MoreThan60Days = 'MoreThan60Days'
}

export class DownloadAppModalVariants extends Enum {
  static QuarterSheet = 'QuarterSheet'
  static HalfSheet = 'HalfSheet'
  static FullSheetGeneral = 'FullSheetGeneral'
  static FullSheetFinishBooking = 'FullSheetFinishBooking'
}

/**
 * @enum {string}
 */
export class ReportTypeEnum extends Enum {
  static Soliciting = 'Soliciting'
  static Abusive = 'Abusive'
  static Greymarket = 'Greymarket'
  static Other = 'Other'
}

export class RvListingRequirements extends Enum {
  static MinimumDescriptionLength = 200
}

export class PaymentMethodType extends Enum {
  static Unknown = 0
  static CreditCard = 1
  static Affirm = 2
}

export class SeatbeltLimits extends Enum {
  static Minimum = 0
  static Maximum = 12
}

export class DistanceUnitEnumKey extends Enum {
  static km = 'km'
  static mi = 'mi'
}

export class Cookies extends Enum {
  static AppDownloadBannerDismissed = 'app_download_banner_dismissed'
  static Platform = 'platform'
  static ExperimentOverrides = 'experiment_overrides'
  static Capabilities = 'capabilities'
  static AppDownloadLoggedIn = 'app_download_logged_in_dismissed'
  static AppDownloadInactive = 'app_download_inactive_dismissed'
  static AutomatedTest = 'automated-test'
  static Currency = 'currency'
}

export class Platform extends Enum {
  static Android = 'android'
  static IOS = 'ios-v1'
  static Web = 'web'
  static Unknown = 'unknown'
}

export class AuthenticationType extends Enum {
  static Login = 'Login'
  static Signup = 'Signup'
}

export class PreferencesHash extends Enum {
  static Calendar = '#calendar'
  static Pickup = '#pickup'
  static Nights = '#nights'
  static Stays = '#stays'
  static Min = '#min'
  static Max = '#max'
  static CalendarOpen = '#calendar-open'
  static Driving = '#driving'
  static Events = '#events'
}

export class RvUpdateState extends Enum {
  static Pending = 'pending'
  static DeliverPerKM = 'DeliverPerKM'
  static CalendarSettings = 'CalendarSettings'
}

/**
 * @enum {string}
 */
export class CircleIconVariants extends Enum {
  static Danger = 'danger'
  static Highlight = 'highlight'
  static Success = 'success'
  static Warning = 'warning'
  static Primary = 'primary'
}

export class FrenchWeekdaysEnum extends Enum {
  static Monday = 0
  static Tuesday = 1
  static Wednesday = 2
  static Thursday = 3
  static Friday = 4
  static Saturday = 5
  static Sunday = 6
}

export class CustomExceptions extends Enum {
  static UserError = 'USER_ERROR'
  static LibError = 'LIB_ERROR'
  static DataError = 'DATA_ERROR'
}

export class ActionsTracked extends Enum {
  static DashboardCardDismiss = 'DashboardCardDismiss'
  static RequestToBookGoToStepClicked = 'RequestToBookGoToStepClicked'
  static BookingPaymentDetailsExpandFees = 'BookingPaymentDetailsExpandFees'
  static SearchViewedSegmentWithoutListRVsAndTotalRVsMoreThanZero =
    'SearchViewedSegmentWithoutListRVsAndTotalRVsMoreThanZero'
  static ChangeRequestError = 'ChangeRequestError'
  static ListingViewed = 'ListingViewed'
  static ExperimentViewed = 'ExperimentViewed'
}

export class AllowFestivalEventsParticipation extends Enum {
  static Yes = 'Yes'
  static No = 'No'
  static Negotiable = 'Negotiable'
}

export class FomoTags extends Enum {
  static GreatPrice = 'Great Price'
  static GoodPrice = 'Good Price'
  static HighDemand = 'High Demand'
}

export class DefaultExperimentValues extends Enum {
  static Off = 'off'
  static Variant = 'variant'
  static Control = 'control'
}

export class ExperimentKeys extends Enum {
  static rvz20599ThanksgivingBanner = 'rvz20599ThanksgivingBanner'
}

export class ChangeRequestType extends Enum {
  static Dates = 'Dates'
  static Details = 'Details'
  static Delivery = 'Delivery'
  static Addons = 'Addons'
}

export class ChangeRequestErrors extends Enum {
  static OverlappingDates = 'OverlappingDates'
  static GenericError = 'InvalidChangeRequest'
}

export class AddOnType extends Enum {
  static RVAddOn = 'RVAddOn'
  static CleaningFee = 'CleaningFee'
  static Roadside = 'Roadside'
}

export class ErrorsCodeServer extends Enum {
  static UserChangeRequestDuplicate = 'USER_CHANGE_REQUEST_DUPLICATE'
  static BookingOverlapsDates = 'BOOKING_OVERLAPS_DATES'
  static AddonIsDuplicate = 'ADDON_IS_DUPLICATE'
}

export class RangeDiscountTypes extends Enum {
  static Weekly = 'Weekly'
  static Monthly = 'Monthly'
}

export class CalendarEntryReason extends Enum {
  static RvShareBooking = 'RvShareBooking'
  static OutdoorsyBooking = 'OutdoorsyBooking'
  static OutdoorsyBookingV0 = 'OutdoorsyBookingV0'
  static PreparationTime = 'PreparationTime'
}

export class SelectInputComponentVariants extends Enum {
  static Default = 'Default'
  static RadioButton = 'Radiobutton'
}

export class CommonComponentVariants extends Enum {
  static Default = 'Default'
  static Large = 'Large'
}

export class FormInputVariants extends Enum {
  static Primary = 'primary'
  static Light = 'light'
}

export class DeviceType extends Enum {
  static Mobile = 'mobile'
  static Tablet = 'tablet'
  static Desktop = 'desktop'
}

Object.freeze(i18nLocaleEnum)
Object.freeze(BookingPseudoStage)
Object.freeze(SignupUserType)
Object.freeze(PromocodeErrors)
Object.freeze(QueryStrings)
Object.freeze(RouteBaseNameEnum)
Object.freeze(ImageFormat)
Object.freeze(ImageResolution)
Object.freeze(ImageQuality)
Object.freeze(ImageType)
Object.freeze(BookingPaymentStates)
Object.freeze(Manufacturer)
Object.freeze(DateRangeType)
Object.freeze(MonthsAsDays)
Object.freeze(CalendarDayType)
Object.freeze(DashboardTrips)
Object.freeze(DeclinationActions)
Object.freeze(FlagType)
Object.freeze(BadgeColors)
Object.freeze(FuelTypes)
Object.freeze(SearchTypes)
Object.freeze(CancellationPolicyOptions)
Object.freeze(DownloadAppModalVariants)
Object.freeze(ReportTypeEnum)
Object.freeze(RvListingRequirements)
Object.freeze(SeatbeltLimits)
Object.freeze(DistanceUnitEnumKey)
Object.freeze(PaymentMethodType)
Object.freeze(AuthenticationType)
Object.freeze(PreferencesHash)
Object.freeze(RvUpdateState)
Object.freeze(CircleIconVariants)
Object.freeze(FrenchWeekdaysEnum)
Object.freeze(CustomExceptions)
Object.freeze(ActionsTracked)
Object.freeze(FomoTags)
Object.freeze(DefaultExperimentValues)
Object.freeze(ExperimentKeys)
Object.freeze(ChangeRequestType)
Object.freeze(ChangeRequestErrors)
Object.freeze(AddOnType)
Object.freeze(ErrorsCodeServer)
Object.freeze(RangeDiscountTypes)
Object.freeze(CalendarEntryReason)
Object.freeze(SelectInputComponentVariants)
Object.freeze(CommonComponentVariants)
Object.freeze(FormInputVariants)
Object.freeze(DeviceType)
