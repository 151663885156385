import { decodeJSON, encodeJSON } from '~/lib/base64'
import { getConfigCatUserObject, trackExperiment, trackStartup, useConfigCat } from '~/lib/experiments/utils'
import { isWebview } from '~/lib/useUtils'
import { ActionsTracked } from '~/lib/enums/index.js'

let experiments

export default async (
  { nuxtState, $config, $auth, $device, $getSessionID, $geolocation, $evaluatedExperiments, $cookies, $trackAction },
  inject
) => {
  experiments = process.client ? nuxtState.experiments : $evaluatedExperiments

  const configCat = useConfigCat(experiments)

  function trackView({ experimentKey, startup = false }) {
    const experiment = configCat.get(experimentKey)

    $trackAction(ActionsTracked.ExperimentViewed, {
      experiment: experimentKey,
      timestamp: Date.now()
    })

    trackExperiment(experiment, { startup, isWebview: isWebview($cookies.get('platform')) })
  }

  function trackStartupExperiments() {
    trackStartup(configCat.getAll(), isWebview($cookies.get('platform')))
  }

  async function updateOverride(experimentKey, value) {
    const cookie = getOverrideCookie()

    if (value === null) {
      delete cookie[experimentKey]
    } else {
      cookie[experimentKey] = value
    }

    setOverrideCookie(cookie)
  }

  function getOverrideCookie() {
    return decodeJSON($cookies.get('experiment_overrides')) || {}
  }

  function setOverrideCookie(overrides) {
    $cookies.set('experiment_overrides', encodeJSON(overrides), {
      domain: $config.cookieDomain,
      path: '/',
      maxAge: overrides['*permanent'] ? $config.sessionCookieTTL : null
    })
  }

  function getFlag(flag) {
    const cookie = getOverrideCookie()
    return cookie[`*${flag}`]
  }

  function setFlag(flag, value) {
    const cookie = getOverrideCookie()
    cookie[`*${flag}`] = value
    setOverrideCookie(cookie)
  }

  function login() {
    setFlag('authenticated', true)
  }

  function logout() {
    setFlag('authenticated', false)
  }

  function isAuthenticated() {
    return getFlag('authenticated')
  }

  function setDefaultAll(value) {
    setFlag('defaultAll', value)
  }

  function getDefaultAll() {
    return getFlag('defaultAll')
  }

  function setPermanent(value) {
    setFlag('permanent', value)
  }

  function getPermanent() {
    return getFlag('permanent')
  }

  async function refreshExperiments() {
    const utils = {
      user: {
        Id: $auth?.user?.Id,
        Email: $auth?.user?.Email,
        Language: $auth?.user?.Language,
        IsOwner: $auth?.user?.IsOwner
      },
      device: $device,
      session: $getSessionID(),
      ccUser: getConfigCatUserObject({ $auth, $config, $device, $getSessionID, $geolocation, $cookies })
    }

    const response = await fetch(`${$config.baseURL}/api/experiments`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(utils)
    })

    if (process.client) {
      experiments = nuxtState.experiments = await response.json()
    }
  }

  inject('experiment', {
    trackView,
    trackStartupExperiments,
    refreshExperiments,
    updateOverride,
    login,
    logout,
    isAuthenticated,
    setDefaultAll,
    getDefaultAll,
    setPermanent,
    getPermanent,
    ...configCat
  })
}
