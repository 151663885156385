import useRouteManager from '~/lib/useRouteManager'

export default async function ({ route, redirect, $config, $experiment }) {
  const { shouldRedirectRoute, nuxt3BaseUrl, shouldRedirectToNuxt3App } = useRouteManager({
    toPath: route.path,
    $config,
    $experiment
  })

  if (shouldRedirectRoute) {
    redirect(route.fullPath)
  }

  if (shouldRedirectToNuxt3App) {
    redirect(nuxt3BaseUrl + route.fullPath)
  }
}
